const sites = [
    {
        id: "stallingsandassociates",
        type: "business",
        location: "Smithfield, Virginia",
        meta: { description: "Stallings and Associates Webpage" },
        pageTitle: "Stallings and Associates",
        shortTitle: "SAA",
        companyName: "Stallings and Associates, P.C.",
        buttonColor: "rgba(255,255,255,.9)",
        buttonColorText: "#222",
        buttonBorder: "none",
        fontFamily:"Times New Roman, Public Sans, sans-serif",
        floatingContactButton: true,
        floatingContactInfoButton: true,
        floatingBackground: "rgba(0,0,0,.9)",
        contactButtonLabel: "Contact Us",
        notifyOnContact: true,
        notificationEmail: "caryl@stallingsandassociates.com",
        notificationName: "Caryl Ryan",
        compact: false,
        sections: [
            {
                id: "header",
                type: "header",
                display: true,
                fixed: true,
                logo: "/Images/StallingsAndAssociates/stallingtimes.png",
                logoHeight: "80px",
                alt: "Stallings and Associates Webpage",
                shortLogo: true,
                logoCentered: true,
                background: 'rgba(20,20,20,.95)',
                shadow: true,
                actionButton: { action: "portal", color: "#ccc", background: "#09487f", label: "Client Portal", tooltip: "Log in to Portal" },
               
            },
            {
                id: "main",
                type: "main",
                display: true,
                useAsHeader: false,
                background: {
                    image: "/Images/StallingsAndAssociates/stall3.png",
                    color: '#fff',
                    verticalPosition: "bottom",
                    //filter: "grayscale(1)",
                    fullHeight: true,
                },
                contactButton: false,
                contactInfo: true,
                slider: {
                    size: "full",
                    padding: 2,
                    background: "rgba(0,0,0,.8)",
                    bottom: true,
                    topPad:"36px",
                    darkText: false,
                    darkDots: false,
                    items: [
                        //{
                        //    primaryText: "Stallings and Associates - Holiday Closures",
                        //    secondaryText: "Closed November 25th through November 29th."
                        //},
                        //{
                        //    object: true,
                        //    justify: "left",
                        //    list: true,
                        //    secondaryText: "list",
                        //    primaryText: "Stallings and Associates - Holiday Closures",
                        //    lists: [
                        //        {
                        //            title: "Closed November 25th - November 29th",
                        //            items: [
                        //                //"Partnerships (Form 1065)",
                        //                //"S Corporations (Form 1120S)",
                        //            ]
                        //        },
                        //        {
                        //            title: "Closed December 23rd - January 3rd",
                        //            items: [
                        //                //"Individuals and Families (Form 1040)",
                        //                //"Estates and Trusts (Form 1041)",
                        //                //"C Corporations (Form 1120)"
                        //            ]
                        //        },
                        //        //{
                        //        //    title: "May 15, 2024",
                        //        //    items: [
                        //        //        "Tax Exempt Organizations (Form 990)",
                        //        //    ]
                        //        //},
                        //    ],
                        //    bottomText: "We wish you all a wonderful Holiday Season and look forward to serving you in the upcoming year.  Happy Holidays!!"
                        //},
                        {
                            object: true,
                            justify: "left",
                            list: true,
                            secondaryText:"list",
                            primaryText: "2024 Estimated Tax Deadlines",
                            lists: [
                                {
                                    title: "April 15, 2024",
                                    items: [
                                        "1st Quarter",
                                    ]
                                },
                                {
                                    title: "June 17, 2024",
                                    items: [
                                        "2nd Quarter",
                                    ]
                                },
                                {
                                    title: "Sept 16, 2024",
                                    items: [
                                        "3rd Quarter",
                                    ]
                                },
                                {
                                    title: "January 15, 2025",
                                    items: [
                                        "4th Quarter",
                                    ]
                                },
                            ],
                        },
                        {
                            primaryText: "About Us",
                            secondaryText: "Stallings and Associates specializes in Tax Preparation, Accounting and Bookkeeping Services for a wide variety of clients. Our experienced staff always provides personal, quality, and award-winning customer service."
                        },
                    ]
                },
            },
            {
                id: "Services",
                type: "cards",
                display: true,
                title: "Services We Provide...",
               // subtitle: "Our experienced staff provides quality, personal, small-town customer service.",
                titleColor: "#111",
                subtitleColor: "#333",
                background: '#f7f7f7',
                contactButton: true,
                contactButtonMobileOnly: true,
                contactButtonBackground: "#444",
                contactButtonColor: "white",
                imageHeight: 160,
                maxRowSize: 4,
                items: [
                    {
                        primary: "Tax Return Planning and Filing for Individuals",
                        secondary: "",
                    },
                    {
                        primary: "Bookkeeping and Financial Statements for Small Businesses",
                        secondary: "",
                    },
                    {
                        primary: "Corporate Tax Returns",
                        secondary: "",
                    },
                    {
                        primary: "Estate and Trust Accounting and Tax Returns",
                        secondary: "",
                    },
                    {
                        primary: "Business Partnerships ",
                        secondary: "",
                    },
                    {
                        primary: "Tax Exempt Organizations  ",
                        secondary: "",
                    },
                ],
            },
            {
                id: "awards",
                type: "cards",
                display: true,
                title: "In the News...",
                subtitle: "We are proud recipients of the 2021, 2022, and 2023 Hammy Awards for Best Accounting Firm!  The Hammy awards present the local places, faces, businesses and services you think are the Best in Smithfield, Surry and Isle of Wight.",
                titleColor: "#fff",
                subtitleColor: "#ddd",
                background: '#333',
                contactButton: null,
                shadow: true,
                items: [
                    {
                        primary: "Hammy 2023 Winner",
                        image: "/Images/StallingsAndAssociates/hammy2023.png",
                        href: "https://www.smithfieldtimes.com/contests/2023-hammy-awards/"
                    },
                    {
                        primary: "Hammy 2022 Winner",
                        image: "/Images/StallingsAndAssociates/hammy2022.png",
                        href: "https://www.smithfieldtimes.com/contests/hammy-awards-2022/"
                    },
                    {
                        primary: "Hammy 2021 Winner",
                        image: "/Images/StallingsAndAssociates/hammy2021.png",
                        href: "https://issuu.com/smithfieldtimes/docs/2021_hammys"
                    }
                ],
            },
            {
                id: "Links",
                type: "cards",
                display: true,
                title: "Helpful Links...",
                titleColor: "secondary",
                subtitleColor: "#ccc",
                subtitle: "",
                background: '#fff',
                maxRowSize: 6,
                contactButton: null,
                items: [
                    {
                        primary: "Tax Prep Checklist (PDF)",
                        href:  "/Images/StallingsAndAssociates/taxprepchecklist.pdf"
                    },
                    {
                        primary: "2024 Federal Tax Deadlines",
                        href: "/Images/StallingsAndAssociates/2024federaldeadlines.pdf"
                    },
                    {
                        primary: "IRS Website",
                        href: "https://www.irs.gov/filing",
                    },
                    {
                        primary: "Virginia Tax Website",
                        href: "https://www.tax.virginia.gov/",
                    },
                    {
                        primary: "Isle of Wight County Website",
                        href: "https://www.co.isle-of-wight.va.us/index.php",
                    },
                    {
                        primary: "Surry County Website",
                        href: "https://www.surrycountyva.gov/",
                    },
                    {
                        primary: "City of Suffolk Website",
                        href: "https://www.suffolkva.us/",
                    },
                    {
                        primary: "City of Newport News Website",
                        href: "https://www.nnva.gov/",
                    },
                    {
                        primary: "City of Hampton Website",
                        href: "https://hampton.gov/",
                    },
                ],
            },
            //{
            //    id: "partner",
            //    type: "partner",
            //    display: true,
            //    title: "COMING SOON: Client portal powered by:",
            //    titleColor: "#ccc",
            //    disabled: true,
            //    subtitleColor: "#333",
            //    titlePadRight: true,
            //    subtitle: "",
            //    background: '#111',
            //    contactButton: null,
            //    partner: {
            //        logo: "/Images/Common/lsletters.png",
            //        height: "36px",
            //        background: "transparent",
            //        shadow: false,
            //        border: false,
            //        href: "https://mylivesphere.com",
            //        href: null,
            //        alt: "Powered by LiveSphere"
            //    },
            //},
            {
                id: "contactinfo",
                type: "contactinfo",
                display: true,
                title: "Contact Us",
                titleColor: "#ddd",
                subtitle: "",
                background: '#000',
                address: { street: "210 Main Street", city: "Smithfield", "state": "VA", zip: "23430-1325" },
                phone: "(757) 365-0200",
                email: "info@stallingsandassociates.com",
                officeHours: { title: "Office Schedule Varies", items: ["January to April: Mon-Fri", "May to December: Mon-Thur","Office Hours: 9:00 am to 4:00 pm"]}
            },
            {
                id: "footer",
                type: "footer",
                display: true,
                title: "",
                titleColor: "#ddd",
                logo: null,
                alt: "",
                logoHeight: "50px",
                subtitle: "",
                background: '#000',
                partner: false
            },
        ],
        clientPortal: {
            include: true,
            loginLabel: "Client Portal",
            loginLabelDark: false,
            loginLogo: "/Images/StallingsAndAssociates/saapc.png",
            buttonColor: '#333',
            accountRequestMessage: "Need an account? Email us at info@stallingsandassociates.com.",
            contentTypes: ["Files"],
            posts: false,
            postTypes: ["Thoughts",],
            search: false,
            accentColor: '#111',
            mainLogo: "/Images/StallingsAndAssociates/saapc.png",
            mainTitle: "Client Portal",
            allowPost: false,
            allowFilters: false,
            contentTitle: "My Library",
            contentSubtitle: "Create and upload content, and access content shared with you."
        }
    },
    {
        id: "simplebluetech",
        type: "business",
        url: "http://www.simplebluetech.com",
        location: "Smithfield, Virginia",
        pageTitle: "SimpleBlue Technologies",
        shortTitle: "SimpleBlue",
        companyName: "SimpleBlue Technologies, LLC",
        meta: { description: "SimpleBlue Technologies Webpage" },
        buttonColor: "#12172d",
        contactButtonLabel: "Contact Us",
        notifyOnContact: true,
        //notificationEmail: "brian@mylivesphere.com",
        notificationName: "Brian Sabin",
        compact: false,
        sections: [
            {
                id: "header",
                type: "header",
                display: true,
                logo: "/Images/SimpleBlueTech/simplebluehrs.png",
                logoHeight: "120px",
                alt: "SimpleBlue Technologies Hampton Roads Software Development Mobile Docker Kubernetes Cloud React",
                shortLogo: false,
                logoCentered: true,
                background: '#12172d',
                shadow: false,
                actionButton: { action: "portal", color: "white", background: "#09487f", label: "Client Portal", tooltip: "Log in to Portal" },
            },
            {
                id: "main",
                type: "main",
                display: true,
                useAsHeader: false,
                background: {
                    image: null,
                    color: 'linear-gradient( #12172d, 75%,#8488a9)',
                    fullHeight: true,
                },
                contactButton: true,
                slider: {
                    size: "full",
                    background: "rgba(0,0,0,.3)",
                    topPad:"120px",
                    darkText: false,
                    darkDots: false,
                    items: [
                        {
                            primaryText: "Web Development",
                            secondaryText: "We build and host custom websites and data driven applications."
                        },
                        {
                            primaryText: "Integration Services",
                            secondaryText: "We use the latest tech to deliver innovation solutions for integrating your separate systems into a seamless user experience"
                        },
                        {
                            primaryText: "Data Migration Services",
                            secondaryText: "We will help you migrate data, documents, and other information assets from legacy systems to newer platforms.  Migrations can be complex and messy.  We want to handle the dirty work, so you can focus on your business"
                        },
                        {
                            primaryText: "Technology Consulting",
                            secondaryText: "Our goal is to earn your trust so you ultimately consider us a trusted advisor.  You can engage with us for any tech challenge, large or small.  Well-designed and implemeted software can be a game changer for your business and we look forward to a growing partnership"
                        },
                        {
                            primaryText: "Creators of LiveSphere",
                            secondaryText: "Livesphere is the first social media platform focused on improving people's lives.  Create meaningful connections with people across the world with shared interests and passions.  Share content and experiences, learn from one another and grow your personal sphere by making the world a better place.",
                            link: { href: "https://mylivesphere.com", image: "/Images/Common/lsletters.png" }

                        },
                    ]
                },
            },
            {
                id: "Services",
                type: "cards",
                display: true,
                title: "Our Services...",
                titleColor: "#111",
                subtitleColor: "#333",
                subtitle: "We provide a wide range of technology services, enabling you to maximize success by focusing on your clients.",
                background: '#f7f7f7',
                contactButton: true,
                maxRowSize: 6,
                items: [
                    {
                        primary: "Technology Consulting",
                        secondary: "Trusted partner to help create and execute your vision for technology innovation.",
                        image: "/Images/Common/hands.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Integration",
                        secondary: "The best solutions should provide a seamless, integrated user experience.",
                        image: "/Images/Common/bluedev.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Data Migrations",
                        secondary: "Turnkey data cleanup and migrations to new platforms.",
                        image: "/Images/Common/lake.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Software Development",
                        secondary: "Web, mobile, database apps, deployed, secured, and hosted for you.",
                        image: "/Images/Common/code1.jpg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                ],
            },
            {
                id: "partner",
                type: "partner",
                display: true,
                title: "Check out our Client Portal powered by:",
                titleColor: "#ccc",
                subtitleColor: "#333",
                titlePadRight: true,
                subtitle: "",
                background: '#101010',
                contactButton: null,
                shadow:true,
                partner: {
                    logo: "/Images/Common/lsletters.png",
                    height: "70px",
                    background: "transparent",
                    shadow: false,
                    border: false,
                    href: "https://mylivesphere.com",
                    alt: "Powered by LiveSphere"
                },
            },
            {
                id: "Clients",
                type: "cards",
                display: true,
                title: "Who We Serve...",
                titleColor: "#111",
                subtitleColor: "#333",
                subtitle: "We help small to medium sized organizations innovate using technology..",
                background: '#e2e2e2',
                contactButton: false,
                items: [
                    {
                        primary: "Professional Services Firms",
                        secondary: "Financial, consultants, technology, and more.",
                        image: "/Images/Common/hands.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Food and Entertainment",
                        secondary: "Restaurants, Bars, Music Venues, and more.",
                        image: "/Images/Common/bluedev.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Sports and Recreation",
                        secondary: "Clubs, rec leagues, sporting venues, and more.",
                        image: "/Images/Common/lake.jpeg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Many others...",
                        secondary: "Any organization looking to innovate.",
                        image: "/Images/Common/code1.jpg",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                ],
            },
            {
                id: "footer",
                type: "footer",
                display: true,
                title: "",
                titleColor: "#ddd",
                logo: "/Images/SimpleBlueTech/simplebluehrs.png",
                alt: "",
                logoHeight: "50px",
                subtitle: "",
                background: '#000',
                partner: { logo: "/Images/Common/lsletters.png", alt: "Livesphere Social Media", href:"https://mylivesphere.com", logoHeight: "40px" }
            },
        ],
        clientPortal: {
            include: true,
            loginLabel: "Client Portal",
            loginLabelDark: false,
            loginLogo: "/Images/SimpleBlueTech/simplebluehrs.png",
            buttonColor: '#12172d',
            accountRequestMessage: "Need an account? Use the contact form on the main page to send us a message!",
            contentTypes: ["Files", "Links", "Notes", "Lists"],
            posts: false,
            postTypes: ["Thoughts",],
            search: false,
            accentColor: '#12172d',
            mainLogo: "/Images/SimpleBlueTech/simplebluehrs.png",
            mainTitle: "Client Portal",
            allowPost: false,
            allowFilters: false,
            contentTitle: "My Library",
            contentSubtitle: "Create and upload content, and access content shared with you."
        }
    },
    {
        id: "carsonrobertsonmusic",
        type: "musician",
        genres: ["Country",],
        location: "Virginia",
        tags: ["Country", "Hound", "Dog Tight", "Dog Hunting", "Songwriter", "Septa", "Hunting", "Fishing"],
        meta: { description: "Carson Robertson Dog Tight Hound Hunting Musician County Music Artist" },
        pageTitle: "Carson Robertson Music",
        shortTitle: "Carson Robertson",
        companyName: "Carson Robertson Music",
        buttonColor: "#333",
        contactButtonLabel: "Contact",
        notifyOnContact: true,
        compact: true,
        sections: [
            {
                id: "header",
                type: "header",
                display: true,
                fixed: true,
                logo: "/Images/CarsonRobertsonMusic/carsoncursive.png",
                logoHeight: "80px",
                alt: "Carson Robertson Music",
                shortLogo: true,
                logoCentered: true,
                background: 'rgba(0,0,0,.3)',
                shadow: true,
                actionButton: { type: "contact", action: "contact", color: "white", background: "#222", label: "Contact", tooltip: "Send Carson a Message" },          
            },
            {
                id: "main",
                type: "main",
                display: true,
                //useAsHeader: true,
                //header: {
                //    background: 'rgba(0,0,0,.3)',
                //    shadow: false,
                //    titleImage: "/Images/CarsonRobertsonMusic/carson.png",
                //    titleImageHeight: "120px",
                //    titleImageCentered: true,
                //    alt: "Carson Robertson Music",
                //    title: "",
                //    titleColor: "#eee",
                //    subtitle: "",
                //    actionButton: { action: "contact", color: "white", background: "#222", label: "Message Carson", tooltip:"Send Carson a Message" },                   
                //},
                background: {
                    image: "/Images/CarsonRobertsonMusic/bwone.jpg",
                    color: null,
                    fullHeight: true,
                    verticalPosition: "bottom",
                },
                contentAlignment: "right",
                contactButton: null,
                //slider: {
                //    size: "compact",
                //    background: 'rgba(0,0,0,.99)',
                //    darkText: false,
                //    darkDots: false,
                //    items: [
                //        {
                //            primaryText: "Latest Release",
                //            secondaryText: null,
                //            songID: '3897f37b-7e13-4dd5-b598-2d45dabbd8ff'
                //        },
                //        {
                //            primaryText: "Upcoming Appearances",
                //            secondaryText: "Carson will be in downtown Nashville in June, probably playing on the street.",
                //        },
                //    ]
                //},
            },
            {
                id: "Links",
                type: "cards",
                display: true,
                title: "Recent Appearances and other Links",
                titleColor: "#aaa",
                subtitleColor: "#ccc",
                subtitle: "",
                background: '#000',
                contactButton: null,
                items: [
                    {
                        primary: "Tree Talkin' Time",
                        secondary: "Recent podcast appearance",
                        image: "/Images/CarsonRobertsonMusic/treetalkintime.png",
                        href: "https://treetalkintime.libsyn.com/50-dog-tight",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Conkey's Outdoors",
                        secondary: "Family owned and operated.",
                        image: "/Images/CarsonRobertsonMusic/conkys.png",
                        href: "http://www.conkeysoutdoors.com/",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Dog Tight",
                        secondary: "on Spotify",
                        image: "/Images/CarsonRobertsonMusic/spotify.png",
                        href: "https://open.spotify.com/track/4lPcX2s8M1HsB9tQl0RtoY?si=7193f2fb41d54091&fbclid=IwAR3j6MX1ZsdMWzks3BH3LKRny5uKUFSC0hb_GOXb_jdYifaC3W55CAqtm70&nd=1",
                        sectionIDX: null,
                        contentIDX: null,
                    },
                    {
                        primary: "Dog Tight",
                        secondary: "on Apple Music",
                        image: "/Images/CarsonRobertsonMusic/dogtight.jpg",
                        href: "https://music.apple.com/us/album/dog-tight/1655445903?i=1655445904",
                        sectionIDX: null,
                        contentIDX: null,
                    }, 
                ],
            },
            //{
            //    id: "About",
            //    type: "cards",
            //    display: true,
            //    title: "",
            //    titleColor: "#fff",
            //    subtitleColor: "#ddd",
            //    subtitle: "",
            //    background: 'linear-gradient(45deg, lightgray, transparent)',
            //    contactButton: null,
            //    items: [
            //        {
            //            primary: "About Carson",
            //            secondary: "Southeast Virginia Country Life",
            //            image: "/Images/CarsonRobertsonMusic/crfish.jpg",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Buy Some Merch",
            //            secondary: "Online Store Coming Soon",
            //            image: "/Images/CarsonRobertsonMusic/crdeer.jpg",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Dog Tight Video",
            //            secondary: " by Conkey's Outdoors",
            //            image: "/Images/CarsonRobertsonMusic/dogtight.jpg",
            //            href: "https://fb.watch/hH4IzjZxHz/",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //    ],
            //},
            {
                id: "socials",
                type: "socials",
                display: true,
                title: "",
                titleColor: "#333",
                subtitleColor: "#666",
                subtitle: "",
                background: '#050505',
                contactButton: null,
                items: [
                    {
                        primary: "Facebook",
                        secondary: "https://www.facebook.com/carson.robertson.90",
                    },
                    {
                        primary: "Instagram",
                        secondary: "https://www.instagram.com/carson_robertson/",
                    },
                    {
                        primary: "TikTok",
                        secondary: "https://www.tiktok.com/@carsonrobertson97",
                    },
                    {
                        primary: "Spotify",
                        secondary: "https://open.spotify.com/artist/6i3QxTkQrPZdC3CA1riUBL",
                    },
                    {
                        primary: "Amazon Music",
                        secondary: "https://music.amazon.com/artists/B0BMWJV4FT/carson-robertson",
                    },
                    {
                        primary: "YouTube",
                        secondary: "https://www.youtube.com/channel/UCpYiTNcXvOja4klyPEIXTqQ/featured",
                    },
                ],
            },
            {
                id: "partner",
                type: "partner",
                display: true,
                title: "Powered By:",
                titleColor: "#999",
                subtitleColor: "#333",
                titlePadRight: true,
                subtitle: "",
                background: '#050505',
                contactButton: null,
                partner: {
                    logo: "/Images/Common/lsletters.png",
                    height: "36px",
                    background: "transparent",
                    shadow: false,
                    border: false,
                    href: "https://mylivesphere.com",
                    alt: "Powered by LiveSphere"
                },
            },
            {
                id: "footer",
                type: "footer",
                display: true,
                title: "",
                titleColor: "#ddd",
                logo: null,
                alt: "",
                logoHeight: "50px",
                subtitle: "",
                background: '#000',
                partner: false
            },
        ],
        clientPortal: null
    },
    {
        id: "jimoreillyfarms",
        type: "business",
        location: "Smithfield, Virginia",
        meta: { description: "Jim O'reilly Farms Webpage" },
        pageTitle: "Jim O'reilly Farms",
        shortTitle: "JOF",
        companyName: "Jim Oreilly Farms",
        buttonColor: "rgba(255,255,255,.9)",
        buttonColorText: "#222",
        buttonBorder: "none",
        fontFamily: "serif",
        floatingContactButton: true,
        floatingContactInfoButton: true,
        floatingBackground: "rgba(0,0,0,.9)",
        contactButtonLabel: "Contact Us",
        notifyOnContact: true,
        notificationEmail: "brian@mylivesphere.com",
        notificationName: "Brian Sabin",
        compact: false,
        sections: [
            {
                id: "header",
                type: "header",
                display: true,
                fixed: true,
                //logo: "/Images/oreillyfarms/doginhay.jpg",
                //logoHeight: "80px",
                alt: "O'reilly Farms",
                shortLogo: true,
                logoCentered: true,
                background: 'rgba(20,20,20,.85)',
                shadow: true,
                // actionButton: { action: "portal", color: "#ccc", background: "#09487f", label: "Client Portal", tooltip: "Log in to Portal" },

            },
            {
                id: "main",
                type: "main",
                display: true,
                useAsHeader: false,
                background: {
                    image: "/Images/oreillyfarms/cowsandpumpkins.jpg",
                    color: '#fff',
                    verticalPosition: "bottom",
                    //filter: "grayscale(1)",
                    fullHeight: true,
                },
                contactButton: false,
                contactInfo: true,
                slider: {
                    size: "full",
                    padding: 2,
                    background: "rgba(0,0,0,.7)",
                    bottom: true,
                    topPad: "36px",
                    darkText: false,
                    darkDots: false,
                    items: [

                        //{
                        //    object: true,
                        //    justify: "left",
                        //    list: true,
                        //    secondaryText: "list",
                        //    primaryText: "2024 Estimated Tax Deadlines",
                        //    lists: [
                        //        {
                        //            title: "April 15, 2024",
                        //            items: [
                        //                "1st Quarter",
                        //            ]
                        //        },
                        //        {
                        //            title: "June 17, 2024",
                        //            items: [
                        //                "2nd Quarter",
                        //            ]
                        //        },
                        //        {
                        //            title: "Sept 16, 2024",
                        //            items: [
                        //                "3rd Quarter",
                        //            ]
                        //        },
                        //        {
                        //            title: "January 15, 2025",
                        //            items: [
                        //                "4th Quarter",
                        //            ]
                        //        },
                        //    ],
                        //},
                        {
                            primaryText: "About Us",
                            secondaryText: "Hay and farm products serving eastern Virginia and North Carolina"
                        },
                    ]
                },
            },
            {
                id: "Services",
                type: "cards",
                display: true,
                title: "Our Products...",
                // subtitle: "Our experienced staff provides quality, personal, small-town customer service.",
                titleColor: "#111",
                subtitleColor: "#333",
                background: '#f7f7f7',
                contactButton: true,
                contactButtonMobileOnly: true,
                contactButtonBackground: "#444",
                contactButtonColor: "white",
                imageHeight: 160,
                maxRowSize: 4,
                items: [
                    {
                        primary: "Hay",
                        secondary: "Delivery or Self-Pickup",
                    },
                    {
                        primary: "Beef",
                        secondary: "Wide Selection of Fresh Beef Options",
                    },
                    {
                        primary: "Farm Products",
                        secondary: "",
                    },

                ],
            },
            //{
            //    id: "awards",
            //    type: "cards",
            //    display: true,
            //    title: "In the News...",
            //    subtitle: "We are proud recipients of the 2021, 2022, and 2023 Hammy Awards for Best Accounting Firm!  The Hammy awards present the local places, faces, businesses and services you think are the Best in Smithfield, Surry and Isle of Wight.",
            //    titleColor: "#fff",
            //    subtitleColor: "#ddd",
            //    background: '#333',
            //    contactButton: null,
            //    shadow: true,
            //    items: [
            //        {
            //            primary: "Hammy 2023 Winner",
            //            image: "/Images/StallingsAndAssociates/hammy2023.png",
            //            href: "https://www.smithfieldtimes.com/contests/2023-hammy-awards/"
            //        },
            //        {
            //            primary: "Hammy 2022 Winner",
            //            image: "/Images/StallingsAndAssociates/hammy2022.png",
            //            href: "https://www.smithfieldtimes.com/contests/hammy-awards-2022/"
            //        },
            //        {
            //            primary: "Hammy 2021 Winner",
            //            image: "/Images/StallingsAndAssociates/hammy2021.png",
            //            href: "https://issuu.com/smithfieldtimes/docs/2021_hammys"
            //        }
            //    ],
            //},

            //{
            //    id: "partner",
            //    type: "partner",
            //    display: true,
            //    title: "COMING SOON: Client portal powered by:",
            //    titleColor: "#ccc",
            //    disabled: true,
            //    subtitleColor: "#333",
            //    titlePadRight: true,
            //    subtitle: "",
            //    background: '#111',
            //    contactButton: null,
            //    partner: {
            //        logo: "/Images/Common/lsletters.png",
            //        height: "36px",
            //        background: "transparent",
            //        shadow: false,
            //        border: false,
            //        href: "https://mylivesphere.com",
            //        href: null,
            //        alt: "Powered by LiveSphere"
            //    },
            //},
            {
                id: "contactinfo",
                type: "contactinfo",
                display: true,
                title: "Contact Us",
                titleColor: "#ddd",
                subtitle: "",
                background: '#000',
                address: { street: "17182 Fox Ridge Lane", city: "Smithfield", "state": "VA", zip: "23430" },
                phone: "(757) 871-2332",
                email: "oreillyja757@gmail.com",
/*                officeHours: { title: "Phone", items: ["(757) 871-2332",] },*/
                flatlayout: true
            },
            {
                id: "footer",
                type: "footer",
                display: true,
                title: "",
                titleColor: "#ddd",
                logo: null,
                alt: "",
                logoHeight: "50px",
                subtitle: "",
                background: '#000',
                partner: false
            },
        ],
        clientPortal: {
            include: false,
            loginLabel: "Client Portal",
            loginLabelDark: false,
            loginLogo: "/Images/StallingsAndAssociates/saapc.png",
            buttonColor: '#333',
            accountRequestMessage: "Need an account? Email us at info@stallingsandassociates.com.",
            contentTypes: ["Files", "Links", "Notes", "Lists"],
            posts: false,
            postTypes: ["Thoughts",],
            search: false,
            accentColor: '#111',
            mainLogo: "/Images/StallingsAndAssociates/saapc.png",
            mainTitle: "Client Portal",
            allowPost: false,
            allowFilters: false,
            contentTitle: "My Library",
            contentSubtitle: "Create and upload content, and access content shared with you."
        }
    },
    {
        id: "briansabinmusic",
        type: "musician",
        genres: ["Rock","Country","Reggae","Folk"],
        location: "Virginia",
        tags: ["Country", "Hound", "Dog Tight", "Dog Hunting", "Songwriter", "Septa", "Hunting", "Fishing"],
        meta: { description: "Brian Sabin" },
        pageTitle: "Brian Sabin Music",
        shortTitle: "Brian Sabin",
        companyName: "Brian Sabin Music",
        buttonColor: "#333",
        contactButtonLabel: "Contact",
        notifyOnContact: true,
        compact: true,
        sections: [
            {
                id: "header",
                type: "header",
                display: true,
                fixed: true,
                logo: "/Images/BrianSabinMusic/bsm.png",
                logoHeight: "80px",
                alt: "Brian Sabin Music",
                shortLogo: true,
                logoCentered: true,
                background: 'rgba(0,0,0,.3)',
                shadow: true,
                actionButton: { type: "contact", action: "contact", color: "white", background: "#222", label: "Contact", tooltip: "Send Brian a Message" },
            },
            {
                id: "main",
                type: "main",
                display: true,
                //useAsHeader: true,
                //header: {
                //    background: 'rgba(0,0,0,.3)',
                //    shadow: false,
                //    titleImage: "/Images/CarsonRobertsonMusic/carson.png",
                //    titleImageHeight: "120px",
                //    titleImageCentered: true,
                //    alt: "Carson Robertson Music",
                //    title: "",
                //    titleColor: "#eee",
                //    subtitle: "",
                //    actionButton: { action: "contact", color: "white", background: "#222", label: "Message Carson", tooltip:"Send Carson a Message" },                   
                //},
                background: {
                    image: "/Images/BrianSabinMusic/bscover.jpg",
                    color: null,
                    fullHeight: true,
                    verticalPosition: "bottom",
                },
                contentAlignment: "right",
                contactButton: null,
                //slider: {
                //    size: "compact",
                //    background: 'rgba(0,0,0,.99)',
                //    darkText: false,
                //    darkDots: false,
                //    items: [
                //        {
                //            primaryText: "Latest Release",
                //            secondaryText: null,
                //            songID: '3897f37b-7e13-4dd5-b598-2d45dabbd8ff'
                //        },
                //        {
                //            primaryText: "Upcoming Appearances",
                //            secondaryText: "Carson will be in downtown Nashville in June, probably playing on the street.",
                //        },
                //    ]
                //},
            },
            //{
            //    id: "Links",
            //    type: "cards",
            //    display: true,
            //    title: "Recent Appearances and other Links",
            //    titleColor: "#aaa",
            //    subtitleColor: "#ccc",
            //    subtitle: "",
            //    background: '#000',
            //    contactButton: null,
            //    items: [
            //        {
            //            primary: "Tree Talkin' Time",
            //            secondary: "Recent podcast appearance",
            //            image: "/Images/CarsonRobertsonMusic/treetalkintime.png",
            //            href: "https://treetalkintime.libsyn.com/50-dog-tight",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Conkey's Outdoors",
            //            secondary: "Family owned and operated.",
            //            image: "/Images/CarsonRobertsonMusic/conkys.png",
            //            href: "http://www.conkeysoutdoors.com/",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Dog Tight",
            //            secondary: "on Spotify",
            //            image: "/Images/CarsonRobertsonMusic/spotify.png",
            //            href: "https://open.spotify.com/track/4lPcX2s8M1HsB9tQl0RtoY?si=7193f2fb41d54091&fbclid=IwAR3j6MX1ZsdMWzks3BH3LKRny5uKUFSC0hb_GOXb_jdYifaC3W55CAqtm70&nd=1",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Dog Tight",
            //            secondary: "on Apple Music",
            //            image: "/Images/CarsonRobertsonMusic/dogtight.jpg",
            //            href: "https://music.apple.com/us/album/dog-tight/1655445903?i=1655445904",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //    ],
            //},
            //{
            //    id: "About",
            //    type: "cards",
            //    display: true,
            //    title: "",
            //    titleColor: "#fff",
            //    subtitleColor: "#ddd",
            //    subtitle: "",
            //    background: 'linear-gradient(45deg, lightgray, transparent)',
            //    contactButton: null,
            //    items: [
            //        {
            //            primary: "About Carson",
            //            secondary: "Southeast Virginia Country Life",
            //            image: "/Images/CarsonRobertsonMusic/crfish.jpg",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Buy Some Merch",
            //            secondary: "Online Store Coming Soon",
            //            image: "/Images/CarsonRobertsonMusic/crdeer.jpg",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //        {
            //            primary: "Dog Tight Video",
            //            secondary: " by Conkey's Outdoors",
            //            image: "/Images/CarsonRobertsonMusic/dogtight.jpg",
            //            href: "https://fb.watch/hH4IzjZxHz/",
            //            sectionIDX: null,
            //            contentIDX: null,
            //        },
            //    ],
            //},
            {
                id: "socials",
                type: "socials",
                display: true,
                title: "",
                titleColor: "#333",
                subtitleColor: "#666",
                subtitle: "",
                background: '#050505',
                contactButton: null,
                items: [
                    {
                        primary: "Facebook",
                        secondary: "https://www.facebook.com/carson.robertson.90",
                    },
                    {
                        primary: "Instagram",
                        secondary: "https://www.instagram.com/carson_robertson/",
                    },
                    {
                        primary: "TikTok",
                        secondary: "https://www.tiktok.com/@carsonrobertson97",
                    },
                    {
                        primary: "Spotify",
                        secondary: "https://open.spotify.com/artist/6i3QxTkQrPZdC3CA1riUBL",
                    },
                    {
                        primary: "Amazon Music",
                        secondary: "https://music.amazon.com/artists/B0BMWJV4FT/carson-robertson",
                    },
                    {
                        primary: "YouTube",
                        secondary: "https://www.youtube.com/channel/UCpYiTNcXvOja4klyPEIXTqQ/featured",
                    },
                ],
            },
            {
                id: "partner",
                type: "partner",
                display: true,
                title: "Powered By:",
                titleColor: "#999",
                subtitleColor: "#333",
                titlePadRight: true,
                subtitle: "",
                background: '#050505',
                contactButton: null,
                partner: {
                    logo: "/Images/Common/lsletters.png",
                    height: "36px",
                    background: "transparent",
                    shadow: false,
                    border: false,
                    href: "https://mylivesphere.com",
                    alt: "Powered by LiveSphere"
                },
            },
            {
                id: "footer",
                type: "footer",
                display: true,
                title: "",
                titleColor: "#ddd",
                logo: null,
                alt: "",
                logoHeight: "50px",
                subtitle: "",
                background: '#000',
                partner: false
            },
        ],
        clientPortal: null
    },
];



export const getSiteData = (id) => {
    const site = sites.find(x => x.id === id);
    if (site) {
        return site;
    }
    return null;
}

export const getSiteLogo= (id) => {
    const site = sites.find(x => x.id === id);
    if (site) {
        return site.logo;
    }
    return null;
}