import React, { Component } from 'react';
import TextField from '@mui/material/TextField';

export class TextInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <TextField
                className={this.props.dark ? "dark" : "light"}
                autoComplete={!this.props.autoComplete && "new-password"}
                margin={this.props.margin ?? "dense"}
                color="secondary"
                inputProps={{
                    ...this.props.inputProps, key: this.props.inputKey, id: this.props.id, style: { fontSize: ".8rem" }, sx: {
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                        fontSize: ".8rem",
                    } }}
                label={this.props.label}
                type={this.props.type ?? "text"}
                fullWidth
                variant={this.props.variant ?? "standard"}
                defaultValue={this.props.defaultValue && this.props.defaultValue}
                value={this.props.value && this.props.value}
                InputProps={{
                    ...this.props.InputProps,
                    sx: {
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                        fontSize: ".8rem",
                    }
                }}
                InputLabelProps={{ style: { color: this.props.dark ? "#ccc" : "#555", fontSize: ".8rem" } }}
                onBlur={(e) => this.props.onBlur && this.props.onBlur(e.target.value)}
                onChange={(e) => this.props.onChange && this.props.onChange(e.target.value)}
                onKeyPress={this.props.onKeyPress && this.props.onKeyPress}
                autoFocus={this.props.autoFocus}
                required={this.props.required}
            />

        );
    }
}
