import React, { Component } from 'react';

//Custom Components
import { TextInput } from './TextInput'
import { SelectList } from './SelectList';
import { DateSelector } from './DateSelector';
import { capitalize, isValidURL, validateEmail } from '../../DataServices/Utility';

//Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/Help';

export class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validateFieldError: null
        }
    }
    validateFormat = (format, value) => {
        switch (format) {
            case 'url':
                if (!isValidURL(value)) {
                    this.setState({ validateFieldError: value + " is not a valid url" });
                    this.props.setValid(false);
                }
                else {
                    this.setState({ validateFieldError: null });
                    this.props.setValid(true);
                }
            case 'email':
                if (!validateEmail(value)) {
                    this.setState({ validateFieldError: value + " is not a valid email address" });
                    this.props.setValid(false);
                }
                else {
                    this.setState({ validateFieldError: null });
                    this.props.setValid(true);
                }
            default:
                return true;
        }
    }
    onBlur = (value) => {
        if (this.props.onBlur) {
            this.props.onBlur(value);
        }
        if (value && this.props.validateOnBlur) {
            this.validateFormat(this.props.format, value);
        }
    }

    //render
    alert = (message) => {
        return (
            <Alert severity="error" sx={{ width: '100%', py:0,px:1 }}>
                {message}
            </Alert>
            )
    }
    renderDateSelector = () => {
        return (
            <Stack sx={{ display: "flex", width: "100%" }} >
                <DateSelector
                    static
                    label={this.props.label}
                    dark={this.props.dark}
                    value={this.props.value}
                    required={this.props.required}
                    onChange={this.props.onChange}
                    minValue={this.props.minValue}
                    maxValue={this.props.maxValue}
                />
                {this.props.requiredFieldError && this.alert(`${capitalize(this.props.label)} is required`)}
            </Stack>
        )
    }
    renderSelectList = () => {
        return (
            <Stack sx={{ display: "flex", width: "100%" }} >
                <SelectList
                    dark={this.props.dark}
                    ckey={"key" + this.props.label}
                    label={this.props.label}
                    value={this.props.value}
                    required={this.props.required}
                    onChange={this.props.onChange}
                    listItems={this.props.listItems}
                    allowNull={this.props.allowNull}
                />
                {this.props.requiredFieldError && this.alert(`${capitalize(this.props.label)} is required`)}
            </Stack>
        )
    }
    renderTextInput = () => {
        return (
            <Stack sx={{ display: "flex", width: "100%" }} >
                <TextInput
                    dark={this.props.dark}
                    id={"field" + this.props.label}
                    label={this.props.label}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    onBlur={this.onBlur}
                    onChange={this.props.onChange}
                    onKeyPress={this.props.onKeyPress}
                    autoFocus={this.props.autoFocus}
                    required={this.props.required}
                    inputProps={{ maxLength: this.props.maxLength ?? null }}
                />
                {this.props.requiredFieldError && this.alert(`${capitalize(this.props.label)} is required`)}
                {this.state.validateFieldError && this.alert(this.state.validateFieldError)}
            </Stack>
        )
    }
    renderInput = () => {
        switch (this.props.type) {
            case "text": return this.renderTextInput();
            case "select": return this.renderSelectList();
            case "date": return this.renderDateSelector();
            default: return null;
        }
    }

    render() {
        return (
            <Box sx={{ display: "flex", width: "100%", minWidth: "300px" }} >
                {this.renderInput()}
                {this.props.helpText ?
                    <Tooltip title={this.props.helpText}>
                        <HelpIcon fontSize="small" color="secondary" sx={{ my: "auto", ml: 0.5 }} />
                    </Tooltip>
                    :
                    <Box sx={{ width: "24px" }} />
                }

            </Box>
        )
    }
}