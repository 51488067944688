import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'
import { TextInput } from '../Utilities/TextInput';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
//Material UI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

//Data Services
import { setPassword, disableAccount, deleteAccount  } from '../../DataServices/UserService'

export class Account extends Component {
  constructor(props) {
      super(props);
      this.state = {
          loading: true,
          showPasswordReset: false,
          showBlankOldError: false,
          showBlankNewError: false,
          showSavePasswordError: false,
          showConfirmDisableAccount: false,
          showConfirmDeleteAccount: false
      };
  }

    handleSetPassword = () => {
        this.setState({ showPasswordReset: !this.state.showPasswordReset });
    }
    handleSavePassword = async () => {
        const oldP = document.getElementById("oldPassword").value;
        const newP = document.getElementById("newPassword").value;
        if (!oldP) {
            this.setState({ showBlankOldError: true });
        }
        if (!newP) {
            this.setState({ showBlankNewError: true });
        }

        if (oldP && newP) {
            const res = await setPassword(oldP, newP);
            console.log(res);
            if (res !== true) {
                this.setState({ showSavePasswordError: true });
            }
           
            this.setState({ showPasswordReset: false });
        }
    }
    handleShowDisableAccount = () => {
        this.setState({ showConfirmDisableAccount: true });
    }
    handleShowDeleteAccount = () => {
        this.setState({ showConfirmDeleteAccount: true });
    }
    handleDisableAccount = async () => {
        await disableAccount(this.props.user.userID);
        this.props.setToken('');
    }
    handleDeleteAccount = async  () => {
        await deleteAccount(this.props.user.userID);
        this.props.setToken('');
    }
    handleOldChange = (e) => {
        this.setState({ showBlankOldError: false });
    }
    handleNewChange = (e) => {
        this.setState({ showBlankNewError: false });
    }
    passwordReset = () => {

        return (
            <ModalDialog {...this.props} show={this.state.showPasswordReset}
                title="Reset Password"
                subTitle="Use this form to reset your password. For verification, please enter your existing password first."
                handleClose={() => this.setState({ showPasswordReset: false })}
                handleSave={this.handleSavePassword}
                fullWidth={true}
                maxWidth="sm"
            >
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="oldPassword"
                        cid="oldPassword"
                        id="oldPassword"
                        label="Old Password"
                        type="password"
                        onChange={this.handleOldChange}
                    />
                    {this.state.showBlankOldError && <Alert severity="error">Old password can not be blank.</Alert>}
                    <TextInput
                        dark={this.props.dark}
                        cKey="newPassword"
                        cid="newPassword"
                        id="newPassword"
                        label="New Password"
                        type="password"
                        onChange={this.handleNewChange}
                    />
                    {this.state.showBlankNewError && <Alert severity="error">New password can not be blank.</Alert>}
                    {this.state.showSavePasswordError && <Alert severity="error">Error saving password.</Alert>}
                </SectionFieldGroup>
            </ModalDialog>
        )
    } 
    deleteConfirm = () => {

        return (
            <ModalDialog {...this.props} show={this.state.showConfirmDeleteAccount}
                title="Confirm Delete"
                subTitle="Your Account will be permantently deleted.  Click Delete to confirm"
                handleClose={() => this.setState({ showConfirmDeleteAccount: false })}
                handleSave={this.handleDeleteAccount}
                fullWidth={true}
                maxWidth="sm"
                saveLabel="Delete"
            />

        )
    }
    disableConfirm = () => {

        return (
            <ModalDialog {...this.props} show={this.state.showConfirmDisableAccount}
                title="Confirm Disable"
                subTitle="Your Account will be disabled.  Click Disable to confirm"
                handleClose={() => this.setState({ showConfirmDisableAccount: false })}
                handleSave={this.handleDisableAccount}
                fullWidth={true}
                maxWidth="sm"
                saveLabel="Disable"
            />

        )
    }
    contents = () => {
        return (
            <ModalDialog
                show={this.props.show}
                handleClose={this.props.handleClose}
                fullWidth={true}
                maxWidth="xs"
                hideScroll={true}
                closeLabel="Close"
                title="Account Settings"
                dark={this.props.dark}
            >
               
                <Stack spacing={2} sx={{ p: 2 }}>
                    <Button variant="contained" color="secondary" onClick={this.handleSetPassword}>Reset Password</Button>
                    {/*<Button variant="contained" color="secondary" onClick={this.handleShowDisableAccount}>Disable Account</Button>*/}
                    {/*<Button variant="contained" color="secondary" onClick={this.handleShowDeleteAccount}>Delete Account</Button>*/}
                    </Stack>

                {this.state.showPasswordReset && this.passwordReset()}
                {this.state.showConfirmDeleteAccount && this.deleteConfirm()}
                {this.state.showConfirmDisableAccount && this.disableConfirm()}
            </ModalDialog>
            )
    }

    render() {
        return this.contents();
    };
}
