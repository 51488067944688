import React, { Component} from 'react';



//Material UI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ClientDocuments } from '../Business/ClientDocuments';
import { SelectList } from '../Utilities/SelectList';
import { TextInput } from '../Utilities/TextInput';
import { ModalDialog } from '../Utilities/ModalDialog'
import { UserAvatar } from '../Utilities/UserAvatar'
import { SectionItem } from '../Utilities/SectionItem';
import { Section } from '../Utilities/Section';
import { Invitation } from '../Invitations/Invitation';
import { ItemCreator } from '../Utilities/ItemCreator';
//Database Services
import { getClientUserData, isAdminUser, getClients, getClientDocs, newClient, editClient, getClientUsers, getClientUserInvites, getAdminUserInvites, newClientUser } from '../../DataServices/BusinessService'
import { getEnums } from '../../DataServices/Utility'
import { getPublicAttributes, getUserList, getFeedPreferences, setFeedPreferences } from '../../DataServices/UserService'



export class Home extends Component {

    constructor(props) {
        super(props);
        this.props.setToken.bind(this);
        this.state = {
            documentFolders: [],      
            contentUser: false,
            contentLoaded: false,
            dropDownOptions: [],
            selectedContentType: null,
            selectedContentProps: null,
            contentUser: true,
            pauseRefreshes: false,
            bottomMenuValue: 0,
            contentLoading: false,
            height: 0,
            width: 0,
            newClientUserFields : [
                {
                    type: "text",
                    label: "First Name",
                    fieldName: "firstName",
                    value: "",
                    maxLength: 100,
                    required: true
                },
                {
                    type: "text",
                    label: "Last Name",
                    fieldName: "lastName",
                    value: "",
                    maxLength: 100,
                    required: true
                },
                {
                    type: "text",
                    label: "Email",
                    fieldName: "email",
                    value: "",
                    maxLength: 100,
                    required: true,
                    format: "email",
                    validateOnBlur: true
                },
                {
                    type: "text",
                    label: "Default Password",
                    fieldName: "password",
                    value: "Pa$$w0rd",
                    maxLength: 100,
                    required: true
                },
            ]
            //selectedClient: { label: "Test Personal", value: "Test Personal" }
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.getData();

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    //Data Fetching
    getData = async () => {
        const isAdmin = await isAdminUser(this.props.user.userID, this.props.businessID);
        this.setState({ isAdmin });
        this.props.setIsAdmin(isAdmin);
       // this.getClientData();
        if (isAdmin === true) {
            this.getClientList();
        }
        else {
            this.getClientData();
        }

    }
    getDropdownOptions = async () => {
        const data = await getEnums();
        this.setState({ dropDownOptions: data });
    }
    getClientList = async () => {
        const clientData = await getClients(this.props.businessID);
        const clientList = [];
      
        if (clientData && clientData.length > 0) {
            for (var i = 0; i < clientData.length; i++) {
                clientList.push({ label: clientData[i].name, value: clientData[i].id });
            }
            this.setState({ clientData, clientList, selectedClient: clientData[0].id, selectedClientIDX: 0, selectedClientName: clientData[0].name });


            if (clientData[0] && clientData[0].id) {
                this.setCurrentFolders(clientData[0].id, clientData);
            }
        }
    }
    getUserList = async () => {
        const data = await getUserList();
        const userList = [];

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                userList.push({ id: data[i].userID, display: data[i].firstName + ' ' + data[i].lastName });
            }
        }
        this.setState({ userList });
    }
    getClientData = async () => {
        const clientData = await getClientUserData();
        const clientList = [];
        if (clientData && clientData.length > 0) {
            for (var i = 0; i < clientData.length; i++) {
                clientList.push({ label: clientData[i].name, value: clientData[i].id });
            }
            this.setState({ clientData, clientList, selectedClient: clientData[0].id, selectedClientIDX: 0, selectedClientName: clientData[0].name });


            if (clientData[0] && clientData[0].id) {
                this.setCurrentFolders(clientData[0].id, clientData);
            }
        }

    }

    setCurrentFolders = (clientID, clientData) => {
        if (clientID) {
            if (this.state.clientData) {
                var client = this.state.clientData.find(c => {
                    return c.id === clientID
                })
            }
            else {
                var client = clientData.find(c => {
                    return c.id === clientID
                })
            }
            const documentFolders = [];
            if (client) {
                if (client.documentFolders && client.documentFolders.length > 0) {
                    for (var j = 0; j < client.documentFolders.length; j++) {
                        if (client.documentFolders[j] && client.documentFolders[j].id) {
                            documentFolders.push(client.documentFolders[j]);
                        }
                    }
                }
            }
            this.setState({ documentFolders });


        }


    }
    handleClientChange = (e) => {

        this.setCurrentFolders(e);

        var client = this.state.clientData.find(c => {
            return c.id === e
        })

        this.setState({ selectedClient: e, selectedClientName : client.name});
    }
    updateFolders = (documentFolders) => {

        this.setState({ documentFolders });

    }
    selectClient = async (clientID) => {
        if (clientID) {
            var client = this.state.clientData.find(c => {
                return c.id === clientID.value
            })

            const documentFolders = await getClientDocs(clientID.value);
            const inv = await getClientUserInvites(clientID.value);
            const usrs = await getClientUsers(clientID.value);


            this.setState({ documentFolders, selectedClient: clientID.value, selectedClientName: client.name, inv, usrs });
        }
        else {
            const documentFolders = [];

            this.setState({ documentFolders, selectedClient: null, selectedClientName: null });
        }

        
    }
    handleManageClientUsers = () => { }
    handleEditClient = () => { }

    clientSelect = () => {
        if (this.state.clientList) {
            return (
                <Autocomplete
                    loadingText="Loading Clients"
                    onChange={(e, val) => this.selectClient(val) }
                    disablePortal
                    id="clientselector"
                    options={this.state.clientList}
                    sx={{
                        minWidth: 300,
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                                    fontSize: ".8rem"                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Start Typing Name"
                            sx={{
                                color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                                fontSize: ".8rem"
} }

                        />}
                />
                
                
                )
        }
    }
    clientAdder = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.state.showNewClient}
                title={this.state.mode === "new" ? "New Client" : "Edit Client"}
                handleClose={() => this.setState({ showNewClient: false })}
                handleSave={this.state.mode === "new" ? this.handleSaveNewClient : this.handleSaveEditClient}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Close"

            >
                <TextInput
                    {...this.props}
                    cKey="CnewClientName"
                    cid="CnewClientName"
                    id="fldClientName"
                    label="Client Name"
                    defaultValue={this.state.nameValue}
                />
                <TextInput
                    {...this.props}
                    cKey="CnewClientAltID"
                    cid="CnewClientAltID"
                    id="fldClientAltID"
                    label="Alt ID"
                    defaultValue={this.state.altIdValue}
                />
            </ModalDialog>
        )
    } 
    handleShowEditClient = () => {
        const id = this.state.selectedClient;

        this.setState({
            anchorEl: null,
            showNewClient: true,
            mode: "edit",
            nameValue: this.state.clientData.find((x) => x.id === id).name,
            altIdValue: this.state.clientData.find((x) => x.id === id).clientAltID,
        });
    }
    handleSaveNewClient = async () => {
        const ele = document.getElementById("fldClientName");
        if (!ele)
            return;
        const name = ele.value;
        ele.value = "";
        const ele2 = document.getElementById("fldClientAltID");
        if (!ele2)
            return;
        const altid = ele2.value;
        ele2.value = "";

        const newClt = await newClient(this.props.businessID, name, altid);
        if (newClt) {
            const clientData = this.state.clientData;
            clientData.push(newClt);

            const clientList = this.state.clientList;
            clientList.push({ label: newClt.name, value: newClt.id });

            const documentFolders = await getClientDocs(newClt.id);

            this.setState({ clientData, clientList, documentFolders, selectedClient: newClt.id, selectedClientName: newClt.name, showNewClient: false, mode: null });
            let ele3 = document.getElementById("clientselector");
            if (ele3) {
                ele3.value = newClt.id;
            }


        }
    }
    handleSaveEditClient = async () => {
        const ele = document.getElementById("fldClientName");
        if (!ele)
            return;
        const name = ele.value;
        ele.value = "";
        const ele2 = document.getElementById("fldClientAltID");
        if (!ele2)
            return;
        const altid = ele2.value;
        ele2.value = "";

        const newClt = await editClient(this.state.selectedClient, name, altid);
        if (newClt) {
            this.setState(
                state => {
                    let clientData = state.clientData;
                    clientData.find((x) => x.id === this.state.selectedClient).name = name;
                    clientData.find((x) => x.id === this.state.selectedClient).clientAltID = altid;
                    let clientList = state.clientList;
                    clientList.find((x) => x.value === this.state.selectedClient).label = name;
                    return {
                        clientData,
                        clientList,
                        showNewClient: false,
                        selectedClientName: name,
                        mode: null
                    };
                }
            );

        }
    }
    adminTools = () => {
        return (
            <Stack direction="row" spacing={2} sx={{ mx: 1, width:"-webkit-fill-available" }}>
                {this.clientSelect()}

     
                {(this.state.documentFolders && this.state.documentFolders.length > 0) &&
                    <>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="newdoc"
                        onClick={this.handleShowEditClient}
                        >
                            Edit Client
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="newdoc"
                        onClick={() => this.setState({ showClientUsers: true })}
                        >
                            Manage Client Users
                        </Button>
                    </>
                }
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    aria-label="newclient"
                    onClick={() => this.setState({mode: "new", showNewClient: true })}
                >
                    Add New Client
                </Button>
            </Stack>
        )

    }

    handleClientUsers = async () => {

        const inv = await getClientUserInvites(this.state.selectedClient);
        if (inv) {
            this.setState({ inv });
        }


        const usrs = await getClientUsers(this.state.selectedClient);
        if (usrs) {
            this.setState({ usrs });
        }
    }
    handleShowNewClientContact = () => {

    }
    clientUsers = () => {
        if (this.state.showClientUsers) {
            return (
                <ModalDialog
                    {...this.props}
                    show={this.state.showClientUsers}
                    title={"Client Users"}
                    handleClose={() => this.setState({ showClientUsers: false })}
                    fullWidth={true}
                    maxWidth="lg"
                    closeLabel="Close"

                >
                    <Section
                        title=" "
                        key="cliusers"
                        fullWidth
                        dark={this.props.dark}
                        flat
                        transparent
                        handleAdd={() => this.setState({ showNewClientUser: true })}>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            boxShadow: "none",
                            background: "transparent",
                            p: 0.5,
                            m: 0,
                            width: "100%"
                        }}
                        component="div"
                    >
                        {(this.state.inv && this.state.inv.length > 0 && this.state.inv !== "error") && this.state.inv.map((item, index) => {
                            return this.invitedClientUser(item, index)
                        })}
                        {(this.state.usrs && this.state.usrs.length > 0) && this.state.usrs.map((item, index) => {
                            return this.clientUser(item, index)
                        })}
                        </Paper>
                 </Section>
                </ModalDialog>
                
            )
        }
        return null
    }
    invitedClientUser = (item, index) => {
        return (
            <SectionItem
                key={"inivted" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteInvitedClientUser(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <UserAvatar
                        id={item.user.userID}
                        user={item.user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={false}
                        small
                    />
                    <Typography variant="body2" color="secondary" sx={{ ml: 5 }}>
                        {"Invited on " + new Date(item.dateSent).toLocaleDateString()}
                    </Typography>
                </Box>
            </SectionItem>
        )
    }
    clientUser = (item, index) => {
        return (
            <SectionItem
                key={"si" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteClientUser(item.id, index)}
                primaryContent={item.user.firstName + " " + item.user.lastName}
            >
                <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, marginTop: "auto", marginBottom: "auto" }}>
                    {item.user.email}
                </Typography>
            </SectionItem>
        )
    }

    clientUserInvitation = () => {
        if (this.state.showClientUserInvitation) {
            return (
                <ModalDialog
                    {...this.props}
                    show={this.state.showClientUserInvitation}
                    title="Add new users"
                    subTitle="Find users to add by entering their name or email address."
                    fullWidth={true}
                    maxWidth="sm"
                    disableButtons={true}
                >
                    <Invitation
                        user={this.props.user}
                        dark={this.props.dark}
                        appIdentifier={this.props.appIdentifier}
                        updateInviteList={this.updateClientUserInviteList}
                        groupID={this.state.selectedClient}
                        businessID={this.props.businessID}
                        groupName={this.state.selectedClientName}
                        groupType="Client"
                        parentName={this.props.businessName}
                        handleCancel={() => this.setState({ showClientUserInvitation: false })}
                    />
                </ModalDialog>)
        }
        return null;
    } 

    handleNewClientUser = async (values) => {
        if (values && values.length > 0) {
            this.setState({ showNewClientUser: false });
            const user = await newClientUser(this.props.businessID, this.state.selectedClient, ...values);
            if (user) {
                const usrs = this.state.usrs;
                usrs.push(user);
                this.setState({ usrs });
            }

        }
    }
    clientUserCreator = () => {
        if (this.state.showNewClientUser) {
            return (
                <ItemCreator
                    dark={this.props.dark}
                    show={this.state.showNewClientUser}
                    width={this.props.width}
                    user={this.props.user}
                    item="Client User"
                    handleClose={() => this.setState({ showNewClientUser: false })}
                    handleSave={this.handleNewClientUser}
                    fields={this.state.newClientUserFields}
                />
            )
        }
        return null;
    }

    render() {
        return (
            <Box sx={{ pt: { xs: 7, sm: 8 } }} className={this.props.dark && "dark"} >
                {(this.state.clientData) &&
                    <Stack>
                        <Stack direction="row" spacing={2} sx={{ mx: 2, py:1}}>
                            <Typography  sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite, marginTop: "auto", marginBottom: "auto" }}>
                                {this.state.isAdmin ? "Select Client:" : "Select Account"}
                            </Typography>
                            {this.state.isAdmin ? this.adminTools()

                                :

                                <SelectList
                                    dark={this.props.dark}
                                    ckey="clientselect"

                                    value={this.state.selectedClient}
                                    onChange={this.handleClientChange}
                                    listItems={this.state.clientList}
                                    width="fit-content"
                                    minWidth="150px"
                                />
                            }

                        </Stack>
        {/*                {(this.state.documentFolders && this.state.documentFolders.length > 0) &&*/}
                            <ClientDocuments
                            dark={this.props.dark}
                            user={this.props.user}
                            clientID={this.state.selectedClient}
                            clientName={this.state.selectedClientName}
                            appIdentifier={"ls"}
                            profile={this.props.profile}
                            userList={this.props.userList}
                            folders={this.state.documentFolders}
                            updateFolders={this.updateFolders}
                            isAdmin={this.state.isAdmin }
                            />
                       {/* }*/}
                    </Stack>
                }
                {this.clientAdder()}
                {this.clientUsers()}
                {this.clientUserInvitation()}
                {this.clientUserCreator() }
            </Box>
        )
    }
}
