import React, { Component } from 'react';

//Custom Modules
import { ProfileImageUploader } from './ProfileImageUploader';
import { ModalDialog } from '../Utilities/ModalDialog';
import { Section } from '../Utilities/Section';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { DateSelector } from '../Utilities/DateSelector';
import { TextInput } from '../Utilities/TextInput';
import { PublicAttribute } from '../Utilities/PublicAttribute';

//Material UI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Tooltip from '@mui/material/Tooltip';

//Data Services
import { saveName, saveHeadline, saveImportantDate, saveTrait } from '../../DataServices/UserService'

export class ProfileBasic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameChanged: false,
            firstName: this.props.profile.firstName,
            middleName: this.props.profile.middleName,
            lastName: this.props.profile.lastName,
            headline: this.props.profile.demographics.personalHeadline,
            dob: this.props.profile.importantDates.dob,
            wedding: this.props.profile.importantDates.weddingAnniversary,
            job: this.props.profile.importantDates.jobAnniversary,
            showPhotoUploader: false,
            showConfirmDelete: false
        }
    }

    //event handlers
    handleUpdateProfile = (b64) => {
        this.setState({ showPhotoUploader: false });
        this.props.handleUpdateProfile(b64);
    }
    hasNameChanged = () => {
        var first = document.getElementById("firstName").value;
        var middle = document.getElementById("middleName").value;
        var last = document.getElementById("lastName").value;
        //var headline = document.getElementById("headline").value;
        if (first !== this.props.profile.firstName ||
            middle !== this.props.profile.middleName ||
            last !== this.props.profile.lastName ) {
            this.setState({
                nameChanged: true,
                firstName: first,
                middleName: middle,
                lastName: last,

            });
        }
        else {
            this.setState({
                nameChanged: false,
                firstName: first,
                middleName: middle,
                lastName: last,

            });
        }
    }
    saveName = async () => {
        await saveName(this.state.firstName, this.state.middleName, this.state.lastName);
        //await saveHeadline(this.state.headline);
        this.setState({ nameChanged: false });
    }
    handleMyDOBChange = async (e) => {
        this.props.updateMyDob(e);
        await saveImportantDate(0, e);
    }
    handleWeddingChange = async (e) => {
        this.props.updateMyWeddingAnniversary(e);
        await saveImportantDate(1, e);
    }
    handleJobChange = async (e) => {
        this.props.updateMyJobAnniversary(e);
        await saveImportantDate(2, e);
    }
    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }
    handleGenderChange = (e) => {
        this.setState({ genderValue: e.target.value });
    }
    handleDOBChange = (e) => {
        this.setState({ dobValue: new Date(e).toLocaleDateString() });
    }
    handleSexChange = async (e) => {
        this.props.handlePersonalChange("sex", e.target.value);
        await saveTrait(2, e.target.value);
    }
    handleGenderChange = async (e) => {
        this.props.handlePersonalChange("gender", e.target.value);
        await saveTrait(0, e.target.value);
    }
    handleRelationshipChange = async (e) => {
        this.props.handlePersonalChange("relationship", e.target.value);
        await saveTrait(1, e.target.value);
    }
    handlePoliticsChange = async (e) => {
        this.props.handlePersonalChange("politics", e.target.value);
        await saveTrait(3, e.target.value);
    }
    handleReligionChange = async (e) => {
        this.props.handlePersonalChange("religion", e.target.value);
        await saveTrait(4, e.target.value);
    }
    handleSocialChange = async (e) => {
        this.props.handlePersonalChange("social", e.target.value);
        await saveTrait(5, e.target.value);
    }



    render() {

        const ProfilePic = () => {
            return (
                <Section dark={this.props.dark} title="Profile Photo" >
                    <div style={{ textAlign: "center" }}>
                        <Tooltip title="Click to add/update profile photo">
                            {this.props.profile.imageB64 ?
                                <Paper
                                    component="img"
                                    elevation={this.props.dark ? 0 : 4}
                                    src={"data:image/png;base64, " + (this.props.profile.imageB64 ? this.props.profile.imageB64 : this.props.profile.avatarB64)}
                                    style={{ height: "230px", width: "230px", objectFit: "cover", borderRadius: "6px", cursor: "pointer" }}
                                    onClick={() => this.setState({ showPhotoUploader: true })}
                                />
                                :
                                <Button variant="contained" style={{ height: "230px", width: "230px", backgroundColor: this.props.dark ? "#444" : "#bbb", fontSize: "76px" }} onClick={() => this.setState({ showPhotoUploader: true })}>
                                    <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                                </Button>
                            }
                        </Tooltip>

                    </div>
                </Section>)
        } 

        const ImportantDates = () => {
            return (
                <Section dark={this.props.dark} title="Important Dates" >
                    <SectionFieldGroup dark={this.props.dark}>
                        <Box display="flex" sx={{width:"100%"}}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Date of Birth"
                            value={this.props.profile.importantDates.dob !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.dob : null}
                            handleChange={this.handleMyDOBChange}
                            />
                            <PublicAttribute {...this.props} attr={"dob"} />
                        </Box>

                        <Box display="flex" sx={{ width: "100%" }}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Wedding Anniversary"
                            value={this.props.profile.importantDates.weddingAnniversary !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.weddingAnniversary : null}
                            handleChange={this.handleWeddingChange}
                            />
                            <PublicAttribute {...this.props} attr={"weddinganniversary"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Job Anniversary"
                            value={this.props.profile.importantDates.jobAnniversary !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.jobAnniversary : null}
                            handleChange={this.handleJobChange}
                        />
                            <PublicAttribute {...this.props} attr={"jobanniversary"} />
                        </Box>
                    </SectionFieldGroup>
                </Section>)
        } 

        const NameHeadline = () => {
            return (
                <Section dark={this.props.dark} title="Name and Headline" handleSave={this.saveName} enableSave={this.state.nameChanged}>
                    <SectionFieldGroup dark={this.props.dark}>
                        <TextInput
                            dark={this.props.dark}
                            cKey="first"
                            cid="firstNameF"
                            id="firstName"
                            label="First Name"
                            defaultValue={this.state.firstName}
                            onBlur={this.hasNameChanged}
                        />
                        <TextInput
                            dark={this.props.dark}
                            cKey="middle"
                            cid="middleNameF"
                            id="middleName"
                            label="Middle Name"
                            defaultValue={this.state.middleName}
                            onBlur={this.hasNameChanged}
                        />
                        <TextInput
                            dark={this.props.dark}
                            cKey="last"
                            cid="lastNameF"
                            id="lastName"
                            label="Last Name"
                            defaultValue={this.state.lastName}
                            onBlur={this.hasNameChanged}
                        />
                        {/*<TextInput*/}
                        {/*    dark={this.props.dark}*/}
                        {/*    cKey="headline"*/}
                        {/*    cid="headlineF"*/}
                        {/*    id="headline"*/}
                        {/*    label="Personal Headline"*/}
                        {/*    defaultValue={this.state.headline}*/}
                        {/*    onBlur={this.hasNameChanged}*/}
                        {/*/>*/}
                    </SectionFieldGroup>
                </Section>)
        } 

        const PersonalInfo = () => {
            return (
                <Section dark={this.props.dark} title="Personal Information">
                    <SectionFieldGroup dark={this.props.dark}>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="gender"
                            label="My Gender"
                            value={this.props.profile.demographics.gender}
                            onChange={this.handleGenderChange}
                            listItems={this.props.profile.genderList}
                        />
                            <PublicAttribute {...this.props} attr={"gender"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="relationship"
                            label="My Relationship Status"
                            value={this.props.profile.demographics.relationshipStatus}
                            onChange={this.handleRelationshipChange}
                            listItems={this.props.profile.relationshipStatusList}
                            />
                            <PublicAttribute {...this.props} attr={"relationshipstatus"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="sexualPreference"
                            label="My Sexual Preference"
                            value={this.props.profile.demographics.sexualPreference}
                            onChange={this.handleSexChange}
                            listItems={this.props.profile.sexPrefList}
                        />
                            <PublicAttribute {...this.props} attr={"sexualpreference"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="social"
                            label="My Social Type"
                            value={this.props.profile.demographics.socialType}
                            onChange={this.handleSocialChange}
                            listItems={this.props.profile.socialList}
                            />
                            <PublicAttribute {...this.props} attr={"socialtype"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="politics"
                            label="My Politics"
                            value={this.props.profile.demographics.politicalSlant}
                            onChange={this.handlePoliticsChange}
                            listItems={this.props.profile.politicsList}
                        />
                            <PublicAttribute {...this.props} attr={"politics"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <SelectList
                            dark={this.props.dark}
                            ckey="religion"
                            label="My Faith"
                            value={this.props.profile.demographics.religiousViews}
                            onChange={this.handleReligionChange}
                            listItems={this.props.profile.religionList}
                        />
                            <PublicAttribute {...this.props} attr={"religion"} />
                        </Box>
                    </SectionFieldGroup>
                </Section>)
        } 

        const profileImageUploader = () => {
            return (
                <ProfileImageUploader
                    {...this.props}
                    open={this.state.showPhotoUploader}
                    handleSave={this.handleUpdateProfile}
                    handleClose={() => this.setState({ showPhotoUploader: false })}
                    fullScreen={this.props.fullScreen}
  
                />
            )
        }

        const deleteConfirmation = () => {
            return (
                <ModalDialog {...this.props} show={this.state.showConfirmDelete}
                    title="Confirm Deletion"
                    subTitle={this.state.deleteType + " will be deleted.  Proceed?"}
                    handleClose={() => this.setState({ showConfirmDelete: false })}
                    handleSave={this.handleDelete}
                    fullWidth={false}
                    maxWidth="sm"
                    saveLabel="Delete" />
            )
        } 
       
        return (
            <>
                <SectionGrid key="pbasic" ckey="profilebasic" xs={12} md={6} height="unset">
                    {ProfilePic()}
                    {NameHeadline()}
                    {/*{this.props.appIdentifier === 'LS' && ImportantDates()}*/}
                    {/*{this.props.appIdentifier === 'LS' && PersonalInfo()}*/}
                </SectionGrid>
                {profileImageUploader()}
                {deleteConfirmation()}
            </>
        );
    }
}
