import React, { Component } from 'react';

//Custom Components
import TopAppBar from '../../../components/SiteSpecific/TopAppBar';
import { Profile } from '../Profile/Profile';
import { ModalDialog } from '../Utilities/ModalDialog'
import { BottomNav } from '../Utilities/BottomNav';
import { Welcome } from '../../../components/SiteSpecific/Welcome';
import { Affiliations } from '../Groups/Affiliations'
import { Activity } from '../Groups/Activity'
import { ChatDrawer } from '../Messenger/ChatDrawer';
import { Notifications } from '../Notifications/Notifications'
import { Invitations } from '../Invitations/Invitations'
import { Account } from '../Account/Account'

//Database Services
import { getNotifications, updateNotification } from '../../DataServices/NotificationService'
import { getInvitations, confirmInvitation, declineInvitation, removeInvitation } from '../../DataServices/OrganizationService'

export class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            showPhotoUploader: false,
            showSearchResults: false,
            showProfileManager: false,
            showAffiliations: false,
            showNotifications: false,
            showInvitations: false,
            showAccount: false,
            showChat: false,
            notifications: [],
            invitations: [],
            fetchNewPostsInterval: 30000,
            autoFetchNew: true,
            navIndex: 0,
            lastNavIndex: 0,
            width: 0,
            height:0,
        };
    }

    componentDidMount() {
        if (this.props.user && this.props.user.accessToken && !this.props.user.hideWelcome) {
            this.setState({ showWelcome: true });
        }
        this.getInvitations();
        this.getNotifications();
        //wait 1 minute then set auto-fetcher for new posts
        if (this.state.autoFetchNew) {
            setTimeout(
                this.interval = setInterval(() => {
                    this.getNewInvitations();
                    this.getNewNotifications();
                }, this.state.fetchNewPostsInterval),
                60000);
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        //clear autofetch interval if it exists
        if (this.interval)
            clearInterval(this.interval);

        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    getNotifications = async () => {

        const data = await getNotifications(true, this.props.appIdentifier);
        const notifications = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].referencedUser) {
                    notifications.push(data[i]);
                }
            }
            this.setState({
                notifications: notifications,
                loading: false
            });
        }

    }
    getNewNotifications = async () => {

        const data = await getNotifications(true, this.props.appIdentifier);
        const notifications = [];
        if (data) {

            for (var i = 0; i < data.length; i++) {
                if (data[i].referencedUser) {
                    notifications.push(data[i]);
                }
            }
            if (notifications.length === this.state.notifications.length) {
                if (notifications.length === 0) {
                    return;
                }
                else {
                    if (notifications[0].id === this.state.notifications[0].id && notifications[notifications.length - 1].id === this.state.notifications[this.state.notifications.length - 1].id) {
                        return;
                    }
                }
            }
            else {
                this.setState({
                    notifications: notifications,
                    loading: false
                });
            }
        }

    }
    getInvitations = async () => {

        const data = await getInvitations();
        const invitations = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].fromUser) {
                    invitations.push(data[i]);
                }
            }
            this.setState({ invitations });;
        }

    }
    getNewInvitations = async () => {

        const data = await getInvitations();
        const invitations = [];
        if (data) {

            for (var i = 0; i < data.length; i++) {
                if (data[i].fromUser) {
                    invitations.push(data[i]);
                }
            }
            if (invitations.length === this.state.invitations.length) {
                if (invitations.length === 0) {
                    return;
                }
                else {
                    if (invitations[0].id === this.state.invitations[0].id && invitations[invitations.length - 1].id === this.state.invitations[this.state.invitations.length - 1].id) {
                        return;
                    }
                }
            }
            else {
                this.setState({ invitations });
            }
        }

    }
    handleCancel = async (item, index) => {

        this.setState(state => {
            const notifications = state.notifications.filter((item, j) => index !== j);
            return { notifications, };
        });

        let user = this.props.user;
        user.notificationCount--;
        this.props.setUserInfo(user);

        await updateNotification(item.id, 3);

    }
    handleAccept = async (item, index) => {

        this.setState(state => {
            const notifications = state.notifications.filter((item, j) => index !== j);
            return { notifications, };
        });

        let user = this.props.user;
        user.notificationCount--;
        this.props.setUserInfo(user);
        await updateNotification(item.id, 3);

    }
    handleAcceptInvitation = async (item, index) => {
        const invitations = [...this.state.invitations];
        invitations.splice(index, 1);
        this.setState({ invitations });
        await confirmInvitation(item.id);
    }
    handleDeclineInvitation = async (item, index) => {
        const invitations = [...this.state.invitations];
        invitations.splice(index, 1);
        this.setState({ invitations });
        await declineInvitation(item.id);
    }
    handleDismissInvitation = async (item, index) => {
        const invitations = [...this.state.invitations];
        invitations.splice(index, 1);
        this.setState({ invitations });
        await removeInvitation(item.id);
    }
    handleSearch = async (term) => {
        if (term && term.length > 0) {
            this.props.setSearchTerm(term);
            this.handleNavIndex(0);
        }
    }
    handleUpdateProfile = (b64) => {
        this.setState({ showPhotoUploader: false });
        let user = this.props.user;
        user.avatar = b64;
        this.props.setUserInfo(user);
    }
    handleUpdatePrivacy = (privacy) => {

    }
    handleNavIndex = (value) => {
        this.setState({ navIndex: value });
        switch (value) {

            case 0:
                this.setState({ showChat: true, showNotifications: false, showProfileManager: false  });

                break;
            case 1:
                this.setState({ showChat: false, showNotifications: true, showProfileManager: false });
                break;
            case 2:
                this.setState({ showChat: false, showNotifications: false, showProfileManager: true });
                break;

            default:
        }
    }
    handleToggleChat = () => {
        this.setState({ showChat: !this.state.showChat })
        if (this.state.navIndex === 2) {
            this.setState({ navIndex: this.state.lastNavIndex });
        }
    }
    handleCloseProfileManager = () => {
        this.setState({ showProfileManager: false });
        if (this.state.navIndex === 4) {
            this.setState({ navIndex: this.state.lastNavIndex });
        }
    }
    handleCloseNotifications = () => {
        this.setState({ showNotifications: false });
        if (this.state.navIndex === 3) {
            this.setState({ navIndex: this.state.lastNavIndex });
        }
    }
    handleSetTopic = (topic) => {
        if (topic === "posts") {
            this.handleNavIndex(0);
        }
        if (topic === "content") {
            this.handleNavIndex(1);
        }
    }

    //Render Funcs
    chat = () => {
        if (this.state.showChat) {
            return (
                <ChatDrawer
                    show={this.state.showChat}
                    dark={this.props.dark}
                    setToken={this.props.setToken}
                    width={this.state.width}
                    toggleOpen={this.handleToggleChat}
                    user={this.props.user}
                    height={this.state.height}
                />
            )
        }
        return null;
    } 
    welcome = () => {
        return (
            <Welcome
                show={this.state.showWelcome}
                hide={() => this.setState({ showWelcome: false })}
                handleUpdateProfile={this.handleUpdateProfile}
                dark={this.props.dark}
                user={this.props.user}
                setUserInfo={this.props.setUserInfo}
                setDark={this.props.setDark}
                appIdentifier={this.props.appIdentifier}
            />
        )
    }
    profileManager = () => {
        return (
            <ModalDialog
                dark={this.state.width <= 600 ? true : this.props.dark}
                show={this.state.showProfileManager}
                handleClose={this.handleCloseProfileManager}
                closeLabel="Close"
                fullWidth={true}
                title="Profile Manager"
                maxWidth="xl"
                noPad={true}
                fullScreen={this.state.width <= 600}
                solid={this.state.width <= 600}
                hideScroll={true}
                disableButtons
                fullHeight
            >
                <Profile
                    {...this.props}
                    user={this.props.user}
                    setUserInfo={this.props.setUserInfo}
                    fullScreen={this.state.width <= 600}
                />
            </ModalDialog>
        )
    }
    affiliations = () => {
        return (
            <ModalDialog
                dark={true}
                show={this.state.showAffiliations}
                handleClose={() => this.setState({ showAffiliations: false })}
                closeLabel="Close"
                fullWidth={true}
                fullScreen={this.state.width <= 600}
                solid
                title="Affiliations"
                maxWidth="md"
                noPad={true}
                hideScroll={true}
                disableButtons
                fullHeight
                noBorder
            >
                <Affiliations
                    appIdentifier={this.props.appIdentifier}
                    dark={this.props.dark}
                    user={this.props.user}
                />
            </ModalDialog>
        )
    }
    activities = () => {
        return (
            <ModalDialog
                dark={this.state.width <= 600 ? true : this.props.dark}
                show={this.state.showActivity}
                handleClose={() => this.setState({ showActivity: false })}
                closeLabel="Close"
                fullWidth={true}
                fullScreen={this.state.width <= 600}
                solid
                title="My Activity and Connections"
                maxWidth="md"
                noPad={true}
                hideScroll={true}
                disableButtons
                fullHeight
                noBorder
            >
                <Activity
                    appIdentifier={this.props.appIdentifier}
                    dark={this.props.dark}
                    user={this.props.user}
                />
            </ModalDialog>
        )
    }
    notifications = () => {
        return (
            <Notifications
                show={this.state.showNotifications}
                handleClose={this.handleCloseNotifications}
                dark={this.props.dark}
                setUserInfo={this.props.setUserInfo}
                user={this.props.user}
                handleCancel={this.handleCancel}
                handleAccept={this.handleAccept}
                notifications={this.state.notifications}
                setSelectedPost={this.props.setSelectedPost}
                fullScreen={this.state.width <= 600}
            />
        )
    }
    invitations = () => {
        return (
            <Invitations
                show={this.state.showInvitations}
                handleClose={() => this.setState({ showInvitations: false })}
                dark={this.props.dark}
                user={this.props.user}
                handleCancel={this.handleDeclineInvitation}
                handleAccept={this.handleAcceptInvitation}
                handleDecline={this.handleDeclineInvitation}
                invitations={this.state.invitations}
            />
        )
    }
    account = () => {
        return (
            <Account
                show={this.state.showAccount}
                handleClose={() => this.setState({ showAccount: false })}
                dark={this.props.dark}
                user={this.props.user}
                setToken={this.props.setToken}
            />
        )
    }
    bottonNav = () => {
        return (
            <BottomNav dark={this.props.dark} index={this.state.navIndex} setIndex={this.handleNavIndex} notificationCount={this.state.notifications.length ?? 0} hasNewMessages={this.props.user.hasNewMessages }/>
            )
    }
    content = () => {
        return (
            <>
                <TopAppBar
                    user={this.props.user}
                    setToken={this.props.setToken}
                    dark={this.props.dark}
                    setDark={this.props.setDark}
                    getPhoto={this.props.getPhoto}
                    setUserInfo={this.props.setUserInfo}
                    searchCallback={this.handleSearch}
                    handleProfileOpen={() => this.setState({ showProfileManager: !this.state.showProfileManager })}
                    handleAffiliationsOpen={() => this.setState({ showAffiliations: !this.state.showAffiliations })}
                    handleActivityOpen={() => this.setState({ showActivity: !this.state.showActivity })}
                    handleNotificationsOpen={() => this.setState({ showNotifications: !this.state.showNotifications })}
                    handleInvitationsOpen={() => this.setState({ showInvitations: !this.state.showInvitations })}
                    handleAccountOpen={() => this.setState({ showAccount: !this.state.showAccount })}
                    handleChatOpen={() => this.setState({ showChat: !this.state.showChat })}
                    notificationCount={this.state.notifications.length ?? 0}
                    invitationCount={this.state.invitations.length ?? 0}
                    searchTerm={this.props.searchTerm ?? ""}
                    site={this.props.site}
                    //IW props
                    authenticated={this.props.authenticated}
                    newRegistration={this.props.newRegistration}
                    setNewRegistration={this.props.setNewRegistration}
                    setAuthenticated={this.props.setAuthenticated}
                    setTopic={this.handleSetTopic}
                    navIndex={this.state.navIndex}
                    appOptions={this.props.appOptions}
                    isAdmin={this.props.isAdmin}
                    handleShowAdmin={() => this.setState({ showAdmin: true })}
                    handleShowNewUploads={() => this.setState({ showNewUploads: true })}
                />

                {this.props.children}

                {this.state.showWelcome && this.welcome()}
                {this.state.showChat && this.chat()}
                {this.state.showProfileManager && this.profileManager()}
                {this.state.showAffiliations && this.affiliations()}
                {this.state.showActivity && this.activities()}
                {this.state.showNotifications && this.notifications()}
                {this.state.showInvitations && this.invitations()}
                {this.state.showAccount && this.account()}
            </>
        );
    }

    //main component render
    render() {
        return this.content();
    }
}
