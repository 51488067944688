import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import MoreIcon from '@mui/icons-material/MoreVert';
import FeedIcon from '@mui/icons-material/Feed';
import TimelineIcon from '@mui/icons-material/Timeline';
import LogoutIcon from '@mui/icons-material/Logout';
import LaunchIcon from '@mui/icons-material/Launch';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SmsIcon from '@mui/icons-material/Sms';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(.5, .5, .5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));
PrimarySearchAppBar.propTypes = {
    user: PropTypes.object,
    setToken: PropTypes.func.isRequired
};

export default function PrimarySearchAppBar({
    user,
    setToken,
    dark,
    setDark,
    handleChatOpen,
    searchCallback,
    handleProfileOpen,
    handleAffiliationsOpen,
    handleActivityOpen,
    handleNotificationsOpen,
    notificationCount,
    handleAccountOpen,
    invitationCount,
    handleInvitationsOpen,
    searchTerm,
    site,
    setTopic,
    navIndex,
    appOptions,
    isAdmin,
    handleShowAdmin,
    handleShowNewUploads
}) {

    //Constants
    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    //State variables
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    //event handlers
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleGoToLivesphere = (event) => {
        window.open("https://mylivesphere.com", '_blank');
    }
    const logoutUser = async () => {
        return fetch('home/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    const handleLogout = async () => {
        handleMenuClose();
        await logoutUser();
        setToken('');
        localStorage.clear();
    }
    const handleChat = () => {
        handleMenuClose();
        handleChatOpen();
    }
    const handleProfile= () => {
        handleMenuClose();
        handleProfileOpen();
    }
    const handleAffiliations = () => {
        handleMenuClose();
        handleAffiliationsOpen();
    }
    const handleActivity = () => {
        handleMenuClose();
        handleActivityOpen();
    }
    const handleAccount = () => {
        handleMenuClose();
        handleAccountOpen();
    }
    const handleNotification = () => {
        handleMenuClose();
        handleNotificationsOpen();
    }
    const handleInvitation = () => {
        handleMenuClose();
        handleInvitationsOpen();
    }
    const handleSetDarkMode = () => {
        setDark(!dark);
        setAnchorEl(false);
    }
    const handleSearch = async (e) => {
        var term = e.target.value;
        searchCallback(term);

    }
    const onSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event);
        }
    }
    const handleSetTopic = () => {
        handleMenuClose();
        setTopic("content");
    }

    //render funcs
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadiusSm,
        transition: "1s",
        backgroundColor: dark ? "#000" : "#f0f0f0",
        border: dark ? "none" : "1px solid #dadada",
        color: dark ? theme.palette.common.dimwhite : theme.palette.common.black,
        opactity: .9,
        '&:hover': {
            backgroundColor: dark ? "#111" : "#e0e0e0",
            opactity: .8,
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));
    const renderMenu = () => {
        return (<Menu
            PaperProps={{ elevation: 4, style: { backgroundColor: dark ? "#111" : "#fff", color: dark ? "#ccc" : "#444" } }}
            MenuListProps={{ style: { backgroundColor: dark ? "#111" : "#fff", color: dark ? "#ccc" : "#444", } }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}

        >
            <MenuItem>
                <FormControlLabel control={<Switch
                    checked={dark}
                    color="secondary"
                    onChange={handleSetDarkMode}
                    size="small"
                    inputProps={{ 'aria-label': 'controlled' }}

                />
                }
                    label={<Typography style={{ fontSize: ".9rem", paddingLeft: "24px" }}>Dark Mode</Typography>}
                />
            </MenuItem>
            <Divider />
            {notificationCount > 0 &&
                <MenuItem onClick={handleNotification}>
                    <ListItemIcon>
                        <Badge badgeContent={notificationCount ?? null} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </ListItemIcon>
                    <Typography style={{ fontSize: ".9rem" }} variant="inherit">{notificationCount === 0 ? "No Notifications" : notificationCount + " Notifications"}</Typography>


                </MenuItem>
            }
            {isAdmin &&
                <MenuItem onClick={handleShowAdmin}>
                    <ListItemIcon>

                            <AdminPanelSettingsIcon />

                    </ListItemIcon>
                    <Typography style={{ fontSize: ".9rem" }} variant="inherit">Manage Business</Typography>


                </MenuItem>
            }
            {isAdmin &&
                <MenuItem onClick={handleShowNewUploads}>
                    <ListItemIcon>

                        <FeedIcon />

                    </ListItemIcon>
                    <Typography style={{ fontSize: ".9rem" }} variant="inherit">File Uploads</Typography>


                </MenuItem>
            }
            {/*{invitationCount > 0 &&*/}
            {/*    <MenuItem onClick={handleInvitation}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <Badge badgeContent={invitationCount ?? null} color="error">*/}
            {/*                <GroupAddIcon />*/}
            {/*            </Badge>*/}
            {/*        </ListItemIcon>*/}
            {/*    <Typography style={{ fontSize: ".9rem" }} variant="inherit">{invitationCount === 0 ? "No Invitations" : (invitationCount === 1 ? invitationCount + " Invitation" : invitationCount + " Invitations")}</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}
            {/*{(user) &&*/}
            {/*    <MenuItem onClick={handleChat}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <Badge invisible={!user.hasNewMessages} color="secondary" variant="dot">*/}
            {/*                <SmsIcon fontSize="small" />*/}
            {/*            </Badge>*/}
            {/*        </ListItemIcon>*/}
            {/*        <Typography style={{ fontSize: ".9rem" }} variant="inherit">{user.hasNewMessages ? "New Messages" : "Conversations"}</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}
            {/*{navIndex !== 1 &&*/}
            {/*    <MenuItem onClick={handleSetTopic} sx={{ display: { xs: "none", lg: "flex" } }}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <WidgetsIcon fontSize="small" />*/}
            {/*        </ListItemIcon>*/}
            {/*        <Typography variant="inherit">My Content</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}
            <MenuItem onClick={handleProfile}>
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <Typography style={{ fontSize: ".9rem" }} variant="inherit">Profile</Typography>
            </MenuItem>

            {/*{site === null &&*/}
            {/*    <MenuItem onClick={handleActivity}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <TimelineIcon fontSize="small" />*/}
            {/*        </ListItemIcon>*/}
            {/*        <Typography style={{ fontSize: ".9rem" }} variant="inherit">Activity</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}
            <MenuItem onClick={handleAccount}>
                <ListItemIcon>
                    <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                <Typography style={{ fontSize: ".9rem" }} variant="inherit">Account</Typography>
            </MenuItem>

            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography style={{ fontSize: ".9rem" }} variant="inherit">Sign Out</Typography>
            </MenuItem>
        </Menu>)
    } ;
    const renderMobileMenu = () => {
        return (<Menu
            PaperProps={{ elevation: 4, style: { backgroundColor: dark ? "#111" : "#fff", color: dark ? "#ccc" : "#444" } }}
            MenuListProps={{ style: { backgroundColor: dark ? "#111" : "#fff", color: dark ? "#ccc" : "#444" } }}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem>
                <FormControlLabel control={<Switch
                    checked={dark}
                    color="secondary"
                    onChange={handleSetDarkMode}
                    size="small"
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                }

                    label="Dark Mode"
                />
            </MenuItem>
            {(notificationCount > 0) &&
                <MenuItem onClick={handleNotification}>
                    <ListItemIcon>
                        <Badge badgeContent={notificationCount ?? null} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </ListItemIcon>
                    <Typography variant="inherit">{notificationCount === 0 ? "No Notifications" : notificationCount + " Notifications"}</Typography>


                </MenuItem>
            }
            {isAdmin &&
                <MenuItem onClick={handleShowAdmin}>
                    <ListItemIcon>

                        <AdminPanelSettingsIcon />

                    </ListItemIcon>
                    <Typography variant="inherit">Manage Business</Typography>


                </MenuItem>
            }
            {isAdmin &&
                <MenuItem onClick={handleShowNewUploads}>
                    <ListItemIcon>

                        <FeedIcon />

                    </ListItemIcon>
                    <Typography variant="inherit">File Uploads</Typography>


                </MenuItem>
            }
            {/*{invitationCount > 0 &&*/}
            {/*    <MenuItem onClick={handleInvitation}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <Badge badgeContent={invitationCount ?? null} color="error">*/}
            {/*                <GroupAddIcon />*/}
            {/*            </Badge>*/}
            {/*        </ListItemIcon>*/}
            {/*    <Typography style={{ fontSize: ".9rem" }} variant="inherit">{invitationCount === 0 ? "No Invitations" : (invitationCount === 1 ? invitationCount + " Invitation" : invitationCount + " Invitations")}</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}
            {/*{(user) &&*/}
            {/*    <MenuItem onClick={handleChat}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <Badge invisible={!user.hasNewMessages} color="secondary" variant="dot">*/}
            {/*                <SmsIcon fontSize="small" />*/}
            {/*            </Badge>*/}
            {/*        </ListItemIcon>*/}
            {/*        <Typography variant="inherit">{user.hasNewMessages ? "New Messages" : "Conversations"}</Typography>*/}


            {/*    </MenuItem>*/}
            {/*}*/}

            <MenuItem onClick={handleProfile}>
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={handleAccount}>
                <ListItemIcon>
                    <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Account</Typography>
            </MenuItem>

            {/*{site === null &&*/}
            {/*    <MenuItem onClick={handleActivity}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <TimelineIcon fontSize="small" />*/}
            {/*        </ListItemIcon>*/}
            {/*        <Typography variant="inherit">Activity</Typography>*/}
            {/*    </MenuItem>*/}
            {/*}*/}

            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Sign Out</Typography>
            </MenuItem>
        </Menu>)
    } 
    const renderAvatar = () => {
        return (
            <Badge invisible={!user.hasNewMessages && !notificationCount} color="info" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} overlap="circular">
                <Avatar className="avatarImageMedium" alt={user.firstName} src={"data:image/png;base64, " + user.avatar} />
            </Badge>
            )
    }
    //const logo = () => {
    //    return (
    //        <Box sx={{ display: { xs: site.clientPortal.search ?  'none' : 'block', sm: 'block' }, marginRight: "10px" }}>
    //            <img src={site.clientPortal.mainLogo} height={site.shortLogo ? "40px" : "40px"} title={site.pageTitle} />
               
    //        </Box>
    //        )
    //} 
    const logo = () => {
        return (
            <>
                <Button color="secondary" sx={{ p: 0, display: { xs: 'none', sm: 'block' }, marginRight: "10px", cursor: "pointer" }} onClick={() => setTopic("posts")}>
                    <img src={site.clientPortal.mainLogo} height="auto" style={{ maxWidth: "260px", }} title={site.pageTitle} />
                </Button>
                <Button color="secondary" sx={{ p: 0, height: "40px", width: "40px", minWidth: 40, display: { xs: 'block', sm: 'none' }, mr: 1, cursor: "pointer" }} onClick={() => setTopic("posts")}>
                    <img src={site.clientPortal.mainLogo} height="auto" style={{ maxWidth: "260px", }} title={site.pageTitle} />
                </Button>
            </>
        )
    } 
    const title = () => {
        let title = site.clientPortal.mainTitle;
        if (isAdmin) {
            title += " Administration";
        }
        return (
            <Box sx={{ display: { xs: 'none', sm: 'block' }, ml:4}}>
                <Typography variant="h6" sx={{ fontWeight: 500, pl: 2, color: "#ccc" }}>{title}</Typography>
            </Box>
        )
    }
    const searchBox = () => {
        return (
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder={"Search " + site.shortTitle}
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyPress={onSearchKeyPress}
                    defaultValue={searchTerm}
                />
            </Search>
        )
    }
    const gapSpacer = () => {
        return (<Box sx={{ flexGrow: 1 }} />)
    }

    return (
        <>
            {renderMobileMenu()}
            {renderMenu()}
            <AppBar
                elevation={dark ? 0 : 8}
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    opacity: 1,
                    backgroundColor: (theme) => dark ? theme.palette.maintoolbar.dark : site.clientPortal.accentColor,
                    borderBottom: theme => dark ? "solid 1px #181818" : "none",
                    color: (theme) => dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite,
                }} >
                <Toolbar>
                    {logo()}
     
                    {title()}
                    {gapSpacer()}
                    {site.clientPortal.search && searchBox()}
  
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="medium"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            sx={{ color: "#e0e0e0" }}
                        >
                            {user ? renderAvatar()  : <AccountCircleIcon /> }
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title="User Options">
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}
