import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

export class SectionItem extends Component {
    content = () => {
        return (
            <Card
                key={this.props.itemID}
                elevation={this.props.dark ? 0 : 4}
                sx={{
                    width: "100%",
                    maxWidth: this.props.maxWidth && this.props.maxWidth,
                    backgroundColor: (theme) => this.props.dark ? (this.props.selected ? theme.palette.selectedItem.dark : theme.palette.item.dark) : (this.props.selected ? theme.palette.selectedItem.light : theme.palette.item.light),
                    mb:1,
                    border:"none",
                    borderRadius: {xs : 0, sm:1}
                }}>
                <CardContent sx={{ p:"6px!important" }}>
                    <Stack direction="row">
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}> 
                            {this.props.primaryChipLabel &&
                                <Chip
                                    size="small"
                                    icon={this.props.chipIcon ?? null}
                                    color={this.props.altChipColor ? "warning" : "secondary"}
                                    label={this.props.primaryChipLabel}
                                    sx={{  minWidth: "80px", m:"auto" }}
                                    onClick={this.props.chipClickEdit ? this.props.chipClickEdit : null}
                                />
                            }
                        </Box>
                        {this.props.primaryContent &&
                            <Typography sx={{ m: "auto", p: 1, fontWeight: 500, fontSize: { xs: ".75rem", sm: "1rem" }, color:(theme) => this.props.dark?theme.palette.common.dimwhite: theme.palette.common.black }}>
                                {this.props.primaryContent}
                            </Typography>
                        }
                        <Box sx={{ display: { xs: "none", sm: "flex" }, px: 2 }}>
                            {this.props.isPrimary &&
                                <Chip
                                    size="small"
                                    color="secondary"
                                    sx={{ m: "auto" }}
                                    icon={<StarIcon />}
                                    label="Primary"
                                    variant="outlined"
                                />
                            }
                        </Box>

                        {this.props.children && this.props.children}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ flexGrow: 0, textAlign: "end" }}>
                            {this.props.handleEdit &&
                                <Tooltip title={this.props.itemType ? "Edit this " + this.props.itemType : "Edit this item"}>
                                    <IconButton onClick={() => this.props.handleEdit && this.props.handleEdit(this.props.itemID, this.props.index)}>
                                        <MoreVertIcon fontSize="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            {this.props.handleDelete &&
                                <Tooltip title={this.props.itemType ? "Delete this " + this.props.itemType : "Delete this item"}>
                                    <IconButton onClick={() => this.props.handleDelete && this.props.handleDelete(this.props.itemID, this.props.index)}>
                                        <DeleteIcon fontSize="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    </Stack>
                </CardContent>
            </Card>

        );
    }

    render() {
        return this.content();
    }
}
