import React, { useState } from 'react';
import { Switch, Route } from "react-router-dom";

//Data Services
import { getPhotoThumb } from './components/DataServices/PhotoService'
import { getUserInfo, getUserTheme, setUserTheme } from './components/DataServices/UserService'
import { getSiteData } from './components/DataServices/SiteDataService'
//Livesphere components
import { Layout } from './components/Common/Layout/Layout';
import { Home } from './components/Common/Layout/Home';
import Login from './components/SiteSpecific/Login';
import ChangePassword from './components/SiteSpecific/ChangePassword';
import useToken from './components/Common/Helpers/useToken';
import { Spin } from './components/Common/Utilities/Spin';
import { Landing } from './components/SiteSpecific/Landing';

//MUI
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ThemeConfig from './theme';

//Styles
import './Styles/common.css';
import './Styles/datagrid.css'



function App() {
   // const appIdentifier = "LS";
    let webPageID;
    let businessid;
    let businessName;
    switch (window.location.hostname) {
        case "simplebluetech.com":
        case "www.simplebluetech.com":
            webPageID = "simplebluetech";
            break;
        case "stallingsandassociates.com":
        case "www.stallingsandassociates.com":
            webPageID = "stallingsandassociates";
            businessid = "555193b5-a13e-4a7f-a15d-3bdd9107a193";
            break;
        case "carsonrobertsonmusic.com":
        case "www.carsonrobertsonmusic.com":
        //case "dogtight.com":
        //case "www.dogtight.com":
            webPageID = "carsonrobertsonmusic";
            break;
        default:
            break;
    }
    if ((window.location.hostname === "localhost" || window.location.hostname === "174.138.118.45") && window.location.pathname.length > 2) {
        webPageID = window.location.pathname.replace("/", "").toLowerCase();
        if (webPageID === "stallingsandassociates") {
            businessid = "555193b5-a13e-4a7f-a15d-3bdd9107a193";
            businessName = "Stallings and Associates";
        }
    }
    let site = getSiteData(webPageID);


    const getLocalUserInfo = () => {
        const localUserString = localStorage.getItem("user");
        let user;
        if (localUserString) {
            try {
                user = JSON.parse(localUserString);
            } catch (e) {
                user = null;
            }
        }

        return user;
    }
    const { token, setToken } = useToken(webPageID);
    const [loaded, setLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [passwordResetRequest, setPasswordResetRequest ] = useState();
    const [userInfo, setUserInfo] = useState(getLocalUserInfo() ?? null);
    const [dark, setDark] = useState(true);
    const [photoRepo, setPhotoRepo] = useState();
    const setTheme = async (isDark) => {
        let theme = "light";
        if (isDark) {
            theme = "dark";
            setDark(true);
            localStorage.setItem("theme", "dark");
        }
        else {
            setDark(false);
            localStorage.setItem("theme", "light");
        }
        await setUserTheme(theme);
    }
    const getTheme = async () => {
        const localTheme = localStorage.getItem("theme");
        let theme;
        if (!localTheme || (localTheme !== "dark" && localTheme != "light")) {
            theme = await getUserTheme();
            localStorage.setItem("theme", theme === "dark" ? "dark" : "light");
        }
        else {
            theme = localTheme;
        }
      
        theme === "dark" ? setDark(true) : setDark(false);
    }
    const getUser = async () => {
        let user = getLocalUserInfo();
        if (!user) {
            user = await getUserInfo(webPageID);
            if (user.success) {
                let userString = JSON.stringify(user);
                localStorage.setItem("user", userString);
            }
            else {
                user = null;
            }
        }
        setUserInfo(user);
    }
    const setUserInfoCallback = (user) => {
        let userString = JSON.stringify(user);
        try {
            localStorage.setItem("user", userString);
        }
        catch (err) {
            console.log(err);
        }

        setUserInfo(user);

    }
    const getPhoto = async (id, size) => {
        let photo = photoRepo.find((x) => x.photoID === id);
        const idx = photoRepo.indexOf(photo);
        if (photo) {
            if (size === "full") {
                if (photo.fullPhoto) {
                    return photo;
                }
                else {
                    photo = await getPhoto(photo.photoID);
                    photoRepo.splice(idx, 1);
                    photoRepo.push(photo);
                    return photo;
                }
            }
            else {
                if (photo.thumb) {
                    return photo;
                }
                else {
                    const thumb = await getPhotoThumb(photo.photoID);
                    if (thumb) {
                        photoRepo[idx].thumb = thumb.thumb;
                    }
                    return photoRepo[idx];
                }
            }
        }
        else {
            if (size === "full") {
                photo = await getPhoto(id)
                photoRepo.push(photo);
                return photo;
            }
            else {
                photo = await getPhotoThumb(id)
                photoRepo.push(photo);
                return photo;
            }

        }
    }
    //const [searchParams, setSearchParams] = useSearchParams();
    const [topic, setTopic] = useState('content');
    const [authenticated, setAuthenticated] = useState(false);
    const [newRegistration, setNewRegistration] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    if (!loaded) {
        if(!userInfo || userInfo.userID === null) getUser();
        getTheme();
        setPhotoRepo([]);
        setLoaded(true);
    }



    const landingSite = () => {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Landing siteID={webPageID} setAuthenticated={setAuthenticated} setToken={setToken} setNewRegistration={setNewRegistration} setUserInfo={setUserInfoCallback} />
            </MuiPickersUtilsProvider>
        )
    }
    const portal = () => {
        return (
            <Layout
                appIdentifier={webPageID}
                setToken={setToken}
                setUserInfo={setUserInfoCallback}
                user={userInfo}
                dark={dark}
                setDark={setTheme}
                getPhoto={getPhoto}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                site={site}
                topic={topic}
                setTopic={setTopic}
                isAdmin={isAdmin}
            >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Home
                        appIdentifier={webPageID}
                        webPageID={webPageID}
                        businessID={businessid}
                        businessName={businessName}
                        setToken={setToken}
                        dark={dark}
                        user={userInfo}
                        searchTerm={searchTerm}
                        site={site}
                        topic={topic}
                        setTopic={setTopic}
                        isAdmin={isAdmin}
                        setIsAdmin={setIsAdmin}
                    />
                </MuiPickersUtilsProvider>
            </Layout>
            )
    }
    const content = () => {
        return (
            <ThemeConfig>

                {landingSite()}

            </ThemeConfig>
            )
    }

    return content();

}

export default App;


